import React from "react";
// import "../home/Index.css";
import "../App.css";
import "../component/HeaderInner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { faHouse } from "@fortawesome/free-solid-svg-icons"
import { Link } from 'react-router-dom';
import { faLinkedinIn } from "@fortawesome/free-brands-svg-icons";
import { faTwitter } from "@fortawesome/free-brands-svg-icons";
import { faFacebookF } from "@fortawesome/free-brands-svg-icons";
import { faArrowRightLong } from "@fortawesome/free-solid-svg-icons";
import { faPinterestP } from "@fortawesome/free-brands-svg-icons";
import { faPaperPlane } from "@fortawesome/free-regular-svg-icons"
import { faArrowLeftLong } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import HeaderInner from "../component/HeaderInner";
import video1 from "../assests/images/speaker-1.mp4";
import video2 from "../assests/images/speaker-2.mp4";
import video3 from "../assests/images/speaker-3.mp4";
import video4 from "../assests/images/speaker-4.mp4";
import FooterInner from "../component/FooterInner";
import { InnerData } from "../component/InnerData";

const About = () => {

    useEffect(() => {
        // Dynamically add Swiper CSS
        const link = document.createElement('link');
        link.rel = 'stylesheet';
        link.href = `/css/swiper-bundle.min.css`;
        document.head.appendChild(link);

        // Dynamically add Swiper JS
        const script = document.createElement('script');
        script.src = 'https://unpkg.com/swiper/swiper-bundle.min.js';
        script.async = true;
        document.body.appendChild(script);

        // Initialize Swiper once the script is loaded
        script.onload = () => {
            const swiper = new window.Swiper('.slider-container', {
                slidesPerView: 4,
                spaceBetween: 25,
                navigation: {
                    /*el: '.swiper-pagination',*/
                    nextEl: '.next',
                    prevEl: '.prev',
                    clickable: true,
                },
                autoplay: true,
                loop: true,
                breakpoints: {
                    300: {
                        slidesPerView: 1,
                        spaceBetween: 20,
                    },
                    // when window width is >= 640px
                    640: {
                        slidesPerView: 1,
                        spaceBetween: 20,
                    },
                    // when window width is >= 768px
                    768: {
                        slidesPerView: 2,
                        spaceBetween: 30,
                    },
                    // when window width is >= 1024px
                    1024: {
                        slidesPerView: 3,
                        spaceBetween: 40,
                    },
                    1200: {
                        slidesPerView: 4,
                        spaceBetween: 40,
                    },
                },
            });

        };

        // Cleanup: remove the script and link elements when the component unmounts
        return () => {
            document.body.removeChild(script);
            document.head.removeChild(link);
        };
    }, []); // Empty array ensures this effect runs only once



    return (

        <div class="rv-14-body">
            <HeaderInner />


            {/* <!-- BREADCRUMB STARTS HERE --> */}


            <div class="rv-breadcrumb pt-120 pb-120 about-page">
                <div class="imageOverlay"></div>
                <div class="container breadBox">
                    <h1 class="rv-breadcrumb__title Aboutsec">About</h1>

                    <ul class="rv-breadcrumb__nav d-flex justify-content-center">
                        <li><Link   to="/"><FontAwesomeIcon   icon={faHouse} /> Home</Link></li>
                        <li class="current-page"><span class="dvdr"> &#47;</span><span class="dvdr">About us</span></li>
                    </ul>
                </div>
            </div>


            {/* <!-- BREADCRUMB ENDS HERE --> */}


            {/* <!-- ABOUT SECTION START --> */}
            <div class="rv-1-about rv-section-spacing" data-aos="fade-up">
                <div class="container position-relative">
                    <div class="row rv-1-about-row g-0 justify-content-between align-items-end">
                        <div class="col-xl-5 col-lg-6">
                            <div class="rv-1-about__img reveal">
                                <img src={require('../assests/images/about-img-1.jpg')} alt="Image" />
                            </div>
                        </div>

                        <div class="col-xxl-6 col-xl-7 col-lg-6">
                            <div class="rv-1-about__txt">
                                <div class="rv-1-section__heading" data-aos="fade-up">
                                    <h6 class="rv-1-section__sub-title rv-text-anime">Welcome to Alpha Infotech</h6>
                                    <h2 class="rv-1-section__title rv-text-anime">Quality is Pride of Workmanship
                                    </h2>
                                </div>

                                <p class="rv-1-about__descr" data-aos="fade-up" data-aos-delay="150">We specialize in creating high-quality, user-centric websites and applications that drive engagement and deliver exceptional digital experiences. Our expert development team combines technical expertise with creative design to build customized solutions tailored to your specific needs.</p>


                                <ul class="rv-1-about__pills" data-aos="fade-up" data-aos-delay="100">
                                    <li class="rv-1-about__pill">Mobile app development for smartphones and tablets</li>
                                    <li class="rv-1-about__pill">Cross-platform development for deploying apps on multiple platforms.</li>
                                    <li class="rv-1-about__pill">Quality assurance and testing to ensure functionality and security.</li>
                                    <li class="rv-1-about__pill">Regular updates and maintenance for optimal performance.</li>
                                    <li class="rv-1-about__pill">Responsive design for compatibility across devices and screen sizes.</li>


                                </ul>

                           

                                {/* <a href="#" class="rv-1-def-btn rv-inner-about-btn" data-aos="fade-up" data-aos-delay="200">
                                    <span class="txt hover-black-clr">Get Tickets</span>
                                    <span class="icon"><img src={require('../assests/images/rv-1-icon-1.png')} alt="icon" /></span>
                                </a>

                                <div class="rv-1-about__right-img">
                                    <img src={require('../assests/images/about-img-2.jpg')} alt="image" />
                                </div> */}
                            </div>
                        </div>
                    </div>

                    <div class="rv-1-about__vectors">
                        <img src={require('../assests/images/rv-1-vector-6.png')} alt="vector" class="rv-1-about__vector rv-1-about__vector-1" />
                        <img src={require('../assests/images/rv-1-vector-7.png')} alt="vector" class="rv-1-about__vector rv-1-about__vector-2" />
                        <img src={require('../assests/images/rv-1-vector-8.png')} alt="vector" class="rv-1-about__vector rv-1-about__vector-3" />
                    </div>
                </div>
            </div>
            {/* <!-- ABOUT SECTION END --> */}



            {/* <!-- SPEAKERS SECTION START --> */}
            {/* <div class="rv-1-container" data-aos="fade-up">
                <section class="rv-1-speakers rv-section-spacing">
                    <div class="container">
                        <div class="rv-1-section-heading rv-1-section-heading-2">
                            <div class="rv-1-section-heading-left">
                                <h6 class="rv-1-section__sub-title rv-text-anime">Event Speakers</h6>
                                <h2 class="rv-1-section__title rv-text-anime">Featured Conference Speakers.</h2>
                            </div>

                            <div class="rv-1-section-heading__right">
                                <a href="#" class="rv-1-def-btn rv-inner-about-btn"><span class="txt">View All
                                    Members</span></a>
                                <div class="rv-1-slider-nav rv-inner-slider-nav swiper-pagination" id="rv-1-speakers__slider-nav">
                                    <button class="prev"><FontAwesomeIcon icon={faArrowLeftLong} /></button>
                                    <button class="next"><FontAwesomeIcon icon={faArrowRightLong} /></button>
                                </div>
                            </div>
                        </div>

                        <div class="rv-1-speakers-slider-container ">
                            <div class="swiper rv-1-speakers__slider slider-container">
                                <div class="swiper-wrapper">
                                    {InnerData.about_Speakers.map((item) => (
                                        <div class="swiper-slide" data-aos="fade-up">
                                            <div class="rv-1-speaker">
                                                <video src={item.video} loop muted="muted" autoPlay="true"></video>
                                                <div class="rv-1-speaker__txt">
                                                    <h6 class="rv-1-speaker__role">{item.title}</h6>
                                                    <h4 class="rv-1-speaker__name">{item.name}</h4>
                                                </div>
                                                <div class="rv-1-speaker__socials">
                                                    <a href="#"><FontAwesomeIcon icon={faTwitter} /></a>
                                                    <a href="#"><FontAwesomeIcon icon={faFacebookF} /></a>
                                                    <a href="#"><FontAwesomeIcon icon={faLinkedinIn} /></a>
                                                </div>
                                            </div>
                                        </div>
                                    ))}

                                    {/* <div class="swiper-slide" data-aos="fade-up" data-aos-delay="200">
                                        <div class="rv-1-speaker">
                                        
                                            <video src={video2} loop muted="muted" ></video>
                                            <div class="rv-1-speaker__txt">
                                                <h6 class="rv-1-speaker__role">Developer Expert</h6>
                                                <h4 class="rv-1-speaker__name">Brandon Guidelines</h4>
                                            </div>
                                            <div class="rv-1-speaker__socials">
                                            <a href="#"><FontAwesomeIcon icon={faTwitter} /></a>
                                        <a href="#"><FontAwesomeIcon icon={faFacebookF} /></a>
                                        <a href="#"><FontAwesomeIcon icon={faLinkedinIn} /></a>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="swiper-slide" data-aos="fade-up" data-aos-delay="400">
                                        <div class="rv-1-speaker">
                                        
                                            <video src={video3} loop muted="muted" ></video>
                                            <div class="rv-1-speaker__txt">
                                                <h6 class="rv-1-speaker__role">Founder, Marks</h6>
                                                <h4 class="rv-1-speaker__name">Spruce Springclean</h4>
                                            </div>
                                            <div class="rv-1-speaker__socials">
                                            <a href="#"><FontAwesomeIcon icon={faTwitter} /></a>
                                        <a href="#"><FontAwesomeIcon icon={faFacebookF} /></a>
                                        <a href="#"><FontAwesomeIcon icon={faLinkedinIn} /></a>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="swiper-slide" data-aos="fade-up" data-aos-delay="600">
                                        <div class="rv-1-speaker">
                                        
                                            <video src={video4} loop muted="muted" ></video>
                                            <div class="rv-1-speaker__txt">
                                                <h6 class="rv-1-speaker__role">Developer Expert</h6>
                                                <h4 class="rv-1-speaker__name">Hermann P. Schnitzel</h4>
                                            </div>
                                            <div class="rv-1-speaker__socials">
                                            <a href="#"><FontAwesomeIcon icon={faTwitter} /></a>
                                        <a href="#"><FontAwesomeIcon icon={faFacebookF} /></a>
                                        <a href="#"><FontAwesomeIcon icon={faLinkedinIn} /></a>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="swiper-slide">
                                        <div class="rv-1-speaker">
                                       
                                            <video src={video2} loop autoPlay="true" ></video>
                                            <div class="rv-1-speaker__txt">
                                                <h6 class="rv-1-speaker__role">Lead Speaker</h6>
                                                <h4 class="rv-1-speaker__name">Thomas R. Toe</h4>
                                            </div>
                                            <div class="rv-1-speaker__socials">
                                            <a href="#"><FontAwesomeIcon icon={faTwitter} /></a>
                                        <a href="#"><FontAwesomeIcon icon={faFacebookF} /></a>
                                        <a href="#"><FontAwesomeIcon icon={faLinkedinIn} /></a>
                                            </div>
                                        </div>
                                    </div> */}
                                {/* </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div> 
            {/* <!-- SPEAKERS SECTION END --> */}




            {/* <!-- CTA SECTION START --> */}
            <section class="rv-14-cta rv-section-spacing">
                <div class="container">
                    <div class="row g-4 align-items-center">
                        <div class="col-lg-6">
                            <div class="rv-14-cta__txt">
                                <h6 class="rv-1-section__sub-title rv-text-anime">Our Newsletter</h6>
                                <h2 class="rv-1-section__title rv-text-anime mb-0">Become a Member For Unique Updates!</h2>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <form action="#" class="rv-6-footer-nwsltr__form rv-14-nwsltr__form">
                                <div class="nwsltr-left">
                                    <input type="email" name="email" placeholder="Enter your Email..." />
                                    <div class="rv-6-footer-nwsltr__checkbox rv-14-nwsltr__checkbox">
                                        <input type="checkbox" id="nwsltr-checkbox" name="checkbox" value="1" />
                                        <label for="nwsltr-checkbox"> I agree to the <a href="#">Privacy Policy</a>.</label>
                                    </div>
                                </div>
                                <button><FontAwesomeIcon icon={faPaperPlane} /> <span class="txt">Subscribe</span></button>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- CTA SECTION END --> */}



            {/* <!-- FOOTER SECTION START --> */}
            <FooterInner />
            {/* <!-- FOOTER SECTION END --> */}

        </div>
    )
}

export default About