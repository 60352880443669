
import React from 'react'
import "../App.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { faHouse } from "@fortawesome/free-solid-svg-icons"
import { Link } from 'react-router-dom';
import { faLinkedinIn } from "@fortawesome/free-brands-svg-icons";
import { faTwitter } from "@fortawesome/free-brands-svg-icons";
import { faFacebookF } from "@fortawesome/free-brands-svg-icons";
import { faArrowRightLong } from "@fortawesome/free-solid-svg-icons";
import { faPinterestP } from "@fortawesome/free-brands-svg-icons";
import { faPaperPlane } from "@fortawesome/free-regular-svg-icons";
import {faArrowLeftLong } from "@fortawesome/free-solid-svg-icons";
import {faPlay} from "@fortawesome/free-solid-svg-icons";
import { useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import HeaderInner from '../component/HeaderInner';
import FooterInner from '../component/FooterInner';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { InnerData } from '../component/InnerData';
import { base } from '../api/constant';

const ProjectD = () => {
  useEffect(() => {
    // Dynamically add Swiper CSS
    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.href = `/css/swiper-bundle.min.css`;
    document.head.appendChild(link);

    // Dynamically add Swiper JS
    const script = document.createElement('script');
    script.src = 'https://unpkg.com/swiper/swiper-bundle.min.js';
    script.async = true;
    document.body.appendChild(script);

    // Initialize Swiper once the script is loaded
    script.onload = () => {
        
      const swiper = new window.Swiper('.swiper',  {
        slidesPerView: 1,
        spaceBetween: 25,
        navigation: {
          nextEl: '.next',
          prevEl: '.prev',
          clickable: true,
      },
        autoplay : true,
        loop : true,
        
      });
     
    };

    // Cleanup: remove the script and link elements when the component unmounts
    return () => {
      document.body.removeChild(script);
      document.head.removeChild(link);
    };
  }, []);
   // Empty array ensures this effect runs only once

  //  const [portfolioDetail, setPortfolioDetail] = useState(null);
  //   const { id } = useParams();
  //   console.log('sdfsdfsdfsfsd',id);
    

  //   useEffect(() => {
  //       // Find the blog item with the matching id
  //       const portItem = InnerData.recent_project.find(item => item.nameID === id);
        
  //       if (id) {
  //         setPortfolioDetail(id);
  //       } else {
  //         setPortfolioDetail(null); // Handle case where no item is found
  //       }
  //   }, [id]);

  //   if (!portfolioDetail) {
  //       return <div>Blog not found!</div>; // Handle the loading or not found case
  //   }

  const { id } = useParams(); // Get the _id from the URL
  const [portfolio, setPortfolio] = useState([]);


  useEffect(() => {
    // Replace with your API endpoint where you're fetching the data
    const fetchPortfolioData = async () => {
      try {
        axios.get(`${base.portfolioDetail}/${id}`).then((response) => {
          if (response.data.statusCode === 200) {
            setPortfolio(response.data.data)
            console.log('portfolio response erwerwerw', response.data.data);
          }
        });
      } catch (error) {
        console.log(error);  
      }
    };

    fetchPortfolioData();
  }, [id]);

//   if (!portfolio) {
//     return <div>No portfolio found.</div>;
//   }
//   const fetchPortfolioData = async () => {
//     try {
//       axios.get(`${base.portfolioDetail}/${id}`).then((response) => {
// if (response.data.statuscode === 200){
//   setPortfolio(response.data.data)
  
// }
//       });

//     } catch(error){
//       console.log(error);
//     }
//   };
//   useEffect(() => {
//     fetchPortfolioData();
//   },[]);


  return (


    <div>
      {/* <!-- HEADER SECTION STARTS HERE --> */}

      <HeaderInner/>

      {/* <!-- HEADER SECTION ENDS HERE --> */}



      {/* <!-- BREADCRUMB STARTS HERE --> */}
      <div class="rv-breadcrumb pt-120 pb-120 portfolio-page">
                <div class="imageOverlay"></div>
                <div class="container breadBox">
                    <h1 class="rv-breadcrumb__title Aboutsec">{portfolio?.title}</h1>

                    <ul class="rv-breadcrumb__nav d-flex justify-content-center">
                        <li><Link   to="/"><FontAwesomeIcon   icon={faHouse} /> Home</Link></li>
                        <li class="current-page"><span class="dvdr"> &#47;</span><span class="dvdr">Portfolio</span>
                        <span class="dvdr"> &#47;</span><span class="dvdr">{portfolio?.title}</span></li>
                    </ul>
                </div>
            </div>
  
      {/* <!-- BREADCRUMB ENDS HERE --> */}



      {/* <!-- PROJECT DETAILS START --> */}
      <section class="rv-project-details rv-section-spacing">
        <div class="container">
          <div class="rv-project-details__cover-slider swiper">
            <div class="swiper-wrapper">
              {portfolio?.assets?.map((sliderImg) => (
                <div class="rv-project-details__img swiper-slide">
                  <img src={sliderImg?.url} alt={sliderImg?.public_id} />
                </div>    
              ))}          
            </div>
            <div class="rv-1-slider-nav rv-7-slider-nav " id="rv-project-details__cover-slider-nav">
              <button class="prev"><FontAwesomeIcon icon={faArrowLeftLong} /></button>
              <button class="next"><FontAwesomeIcon icon={faArrowRightLong} /></button>
            </div>
            
          </div>

          <div class="rv-project-details__txt">
            <div class="left">
              <h3 class="rv-service-details__title">{portfolio?.title}</h3>
              {/* <p class="rv-service-details__descr">It seems like your query is still quite broad. If you could
                provide more specific details or clarify your question about "Digital Product," I'd be happy to
                help. Are you looking for information on creating digital products, marketing them, or something
                else? Feel free to provide more context so I can provide a more targeted and helpful response.
              </p>
              <p class="rv-service-details__descr">Digital product is any product that is delivered and consumed
                in a digital format. Unlike physical products, digital products exist
                solely in electronic form. They can include a wide range of items, from software and e-books to
                online courses and digital artwork.</p> */}
              <div dangerouslySetInnerHTML={{ __html: portfolio?.description}} />
            </div>

            {/* <div class="rv-project-details__infos">
              <div class="rv-project-details-info">
                <h5 class="rv-project-details-info__name">Client:</h5>
                <span class="rv-project-details-info__value">Hermann P. Schnitzel</span>
              </div>

              <div class="rv-project-details-info">
                <h5 class="rv-project-details-info__name">Date:</h5>
                <span class="rv-project-details-info__value">Dec 2024 - Feb 2024</span>
              </div>

              <div class="rv-project-details-info">
                <h5 class="rv-project-details-info__name">Services:</h5>
                <span class="rv-project-details-info__value">Graphic Designing</span>
              </div>
            </div> */}
          </div>

          {/* <div class="rv-project-details__features">
            <div class="row g-30 align-items-center">
              <div class="col-xl-5 col-lg-6">
                <div class="rv-project-details__features-list">
                  <h5>Features</h5>
                  <ul>
                    <li>Quisque posuere hendrerit ultrices.</li>
                    <li>Maecenas eu ante posuere, ullamcorper lectus ac.</li>
                    <li>Ullamcorper erat morbi tempus, ex sed.</li>
                    <li>Condimentum pharetra, dui urna euismod nisi.</li>
                    <li>Praesent tristique eu diam blandit viverra.</li>
                    <li>Quisque porta nisi vel viverra lobortis.</li>
                  </ul>
                </div>
              </div>
              <div class="col-xl-7 col-lg-6">
                <div class="rv-project-details__features-img">
                  <img src={require('../assests/images/rv-project-details-features-img.jpg')} alt="image" />
                </div>
              </div>
            </div>
          </div> */}
        </div>

        {/* <!-- video section --> */}
        {/* <div class="rv-1-video rv-project-details__video">
          <div class="container">
            <div class="row gy-3 align-items-center">
              <div class="col-xl-9 col-lg-8">
                <div class="rv-1-video__txt">
                  <h4 class="rv-1-video__title rv-1-section__title">Live Streaming</h4>
                  <p class="rv-1-video__descr"><span>Event planners help clients organize and coordinate
                    aspects of events.</span></p>
                </div>
              </div>

              <div class="col-xl-3 col-lg-4 text-end">
                <div class="rv-1-video__btn">
                  <a href="https://youtu.be/sk7sfYG0a2A?si=o8USwAmOCBUEzPUy" data-fslightbox><FontAwesomeIcon icon={faPlay} /></a>
                  <span class="txt">Play Video</span>
                </div>
              </div>
            </div>
          </div>
        </div> */}


        {/* <div class="container">
          <div class="rv-project-details__bottom-txt">
            <h3 class="rv-service-details__title">Project Challenges</h3>
            <p class="rv-service-details__descr">Maecenas egestas odio magna, a porttitor nulla vulputate nec.
              Vivamus aliquet mi libero, sed iaculis odio faucibus sit amet. Nam eget quam semper, lacinia diam
              at, malesuada urna. Nullam gravida maximus dignissim. In sit amet leo blandit nibh tempor mattis.
              Praesent at sapien ante. Donec rutrum ligula vitae tellus posuere, et pellentesque est placerat.
              Nullam bibendum varius metus id vulputate. Ut pellentesque commodo convallis. Praesent cursus
              dignissim efficitur. Nunc vitae consectetur massa, non imperdiet dui. Vestibulum eu libero dictum,
              sodales arcu ac, tempor arcu.</p>
            <p class="rv-service-details__descr">Donec ac turpis ac nulla tempor laoreet ut et risus. In posuere
              metus nec lacus gravida, vel malesuada quam semper. Integer sodales lacinia tortor, sed faucibus
              massa porttitor id. Cras at ante nulla. Donec maximus purus et pulvinar fringilla. Vivamus
              vestibulum ipsum et placerat faucibus. Praesent blandit suscipit justo, quis lacinia lacus dignissim
              vel. Nam euismod commodo elit, ut porttitor odio tempus a. Phasellus interdum urna quam, et ultrices
              orci ultrices in. Pellentesque gravida in risus sit amet tincidunt.</p>
          </div>

          <div class="rv-project-details__bottom-imgs">
            <div class="row justify-content-center row-cols-lg-3 row-cols-sm-2 row-cols-1 g-30">
              <div class="col">
                <div class="rv-project-details__bottom-img">
                  <img src={require('../assests/images/rv-1-blog-1.jpg')} alt="image" />
                </div>
              </div>

              <div class="col">
                <div class="rv-project-details__bottom-img">
                  <img src={require('../assests/images/rv-1-blog-2.jpg')} alt="image" />
                </div>
              </div>

              <div class="col">
                <div class="rv-project-details__bottom-img">
                  <img src={require('../assests/images/rv-1-blog-3.jpg')} alt="image" />
                </div>
              </div>
            </div>
          </div>
        </div> */}

        {/* <!-- bottom nav --> */}
        {/* <div class="container">
          <div class="rv-project-details-bottom-navs mt-30">
            <div class="row gy-0">
              <div class="col-md-6 col-12">
                <a href="#" class="rv-project-details-bottom-nav">
                  <span class="rv-project-details-bottom-nav-arrow"><FontAwesomeIcon icon={faArrowLeftLong} /></span>
                  <div class="rv-project-details-bottom-nav-txt">
                    <span class="rv-project-details-bottom-nav-subtitle">Prev Project</span>
                    <h4 class="rv-project-details-bottom-nav-title">Digital Marketing</h4>
                  </div>
                </a>
              </div>
              <div class="col-md-6 col-12 text-end">
                <a href="#"
                  class="rv-project-details-bottom-nav rv-project-details-bottom-nav-next justify-content-end">
                  <div class="rv-project-details-bottom-nav-txt">
                    <span class="rv-project-details-bottom-nav-subtitle">Next Project</span>
                    <h4 class="rv-project-details-bottom-nav-title">Graphic Designing</h4>
                  </div>
                  <span class="rv-project-details-bottom-nav-arrow"><FontAwesomeIcon icon={faArrowRightLong} /></span>
                </a>
              </div>
            </div>
          </div>
        </div> */}
      </section>
      {/* <!-- PROJECT DETAILS END --> */}



      {/* <!-- FOOTER SECTION START --> */}
      <FooterInner/>
      {/* <!-- FOOTER SECTION END --> */}



    </div>
  )
}

export default ProjectD