import React from "react";
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';


const Fullgallery = ({images, onClose}) => {
  return (
    <div className="full-gallery">
    <ImageGallery
      items={images}
      showThumbnails={false}
      showFullscreenButton={true}
      showBullets={true}
      onClose={onClose}
    />
    <button onClick={onClose} className="close-gallery"><i class="fa-solid fa-xmark"></i></button>
  </div>
  )
}

export default Fullgallery