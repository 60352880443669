import React, { useEffect, useState } from 'react'
import "../App.css"
import HeaderInner from '../component/HeaderInner';
import FooterInner from '../component/FooterInner';
import { Link, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHouse } from "@fortawesome/free-solid-svg-icons";
import { InnerData } from '../component/InnerData';
import axios from 'axios';
import { base } from '../api/constant';


const Blog = () => {
   

    const [data, setdata] = useState([])
    const fetchblog = async () => {
        try{
            axios.get(base.Blog).then ((response) => {
                if(response.data.statusCode === 200){
                    setdata(response.data.data)
                    console.log('abcdefghij', response.data.data);
                }
            });
        }catch (error) {
                console.log(error);
            
        }
    };
    useEffect (() => {
        fetchblog();
    },[])

    

    return (
        <div>
            {/* <!-- HEADER SECTION STARTS HERE --> */}
            <HeaderInner />
            {/* <!-- HEADER SECTION ENDS HERE --> */}

            {/* <!-- BREADCRUMB STARTS HERE --> */}
            <div class="rv-breadcrumb pt-120 pb-120 about-page">
                <div class="imageOverlay"></div>
                <div class="container breadBox">
                    <h1 class="rv-breadcrumb__title Aboutsec">News & Blog</h1>

                    <ul class="rv-breadcrumb__nav d-flex justify-content-center">
                        <li><Link to="/"><FontAwesomeIcon icon={faHouse} /> Home</Link></li>
                        <li class="current-page"><span class="dvdr"> &#47;</span><span class="dvdr">Blog</span></li>
                    </ul>
                </div>
            </div>


            {/* <!-- BREADCRUMB ENDS HERE --> */}


            {/* <!-- BLOG SECTION STARTS HERE ↓ --> */}
            <section class="rv-inner-blogs rv-section-spacing">
                <div class="container">
                    <div class="rv-inner-blogs__row">

                        {data.map((item) => (
                            <div key={item.id} class="rv-1-blog rv-inner-blog">
                                <div class="rv-1-blog__img">
                                    <img src={item.bannerImage.url} alt="Blog Image" />
                                </div>

                                <div class="rv-1-blog__txt">
                                    <ul class="rv-1-blog__infos">
                                        <li><img src={require('../assests/images/rv-1-icon-4.png')} alt="icon" /> March 16, 2024</li>
                                        {/* <li><img src={require('../assests/images/rv-1-icon-5.png')} alt="icon" /> 2 Comments</li> */}
                                    </ul>
                                    <h4 class="rv-1-blog__title">
                                        {/* <Link to={`/blog/${item.nameID}`}>{item.heading}</Link> */}
                                        <Link to={`/blog/${item._id}`}>{item.title}</Link>
                                    </h4>
                                    {/* <Link to={`/blog/${item.nameID}`} class="rv-1-schedule-conf__btn rv-1-blog__btn">Read More  <i
                                        class="fa-light fa-arrow-right"></i></Link> */}
                                    <Link
                                        to={`/blog/${item?._id}`}
                                        className="rv-1-schedule-conf__btn rv-1-blog__btn">
                                        Read More <i className="fa-light fa-arrow-right"></i>
                                    </Link>
                                </div>
                            </div>))}

                        {/* <div class="rv-1-blog rv-inner-blog rv-inner-blog--big">
                            <div class="rv-1-blog__img">
                                <img src={require('../assests/images/Software/blog/rv-34-blog2.jpg')} alt="Blog Image" />
                            </div>

                            <div class="rv-1-blog__txt">
                                <ul class="rv-1-blog__infos">
                                    <li><img src={require('../assests/images/rv-1-icon-4.png')} alt="icon" /> March 12, 2024</li>
                                    <li><img src={require('../assests/images/rv-1-icon-5.png')} alt="icon" /> 5 Comments</li>
                                </ul>
                                <h4 class="rv-1-blog__title"><Link to={"/blogdetail"}>Logistics Mobile Application3</Link></h4>
                                <Link to={"/blogdetail"} class="rv-1-schedule-conf__btn rv-1-blog__btn">Read More  <i
                                    class="fa-light fa-arrow-right"></i></Link>
                            </div>
                        </div> */}


                        {/* <div class="rv-1-blog rv-inner-blog rv-inner-blog--small">
                            <div class="rv-1-blog__img">
                                <img src={require('../assests/images/rv-1-blog-3.jpg')} alt="Blog Image" />
                            </div>

                            <div class="rv-1-blog__txt">
                                <ul class="rv-1-blog__infos">
                                    <li><img src={require('../assests/images/rv-1-icon-4.png')} alt="icon" /> June 15, 2023</li>
                                    <li><img src={require('../assests/images/rv-1-icon-5.png')} alt="icon" /> 5 Comments</li>
                                </ul>
                                <h4 class="rv-1-blog__title"><Link to={"/blogdetail"}> How Developers are Removing The
                                    Speculation.</Link></h4>
                                <Link to={"/blogdetail"} class="rv-1-schedule-conf__btn rv-1-blog__btn">Read More  <i
                                    class="fa-light fa-arrow-right"></i></Link>
                            </div>
                        </div> */}


                    </div>
                </div>
            </section>
            {/* <!-- BLOG SECTION ENDS HERE ↑ --> */}









            {/* <!-- FOOTER SECTION START --> */}
            <FooterInner />
            {/* <!-- FOOTER SECTION END --> */}


        </div>
    )
}

export default Blog