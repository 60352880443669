import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram, faLinkedinIn } from "@fortawesome/free-brands-svg-icons";
import { faTwitter } from "@fortawesome/free-brands-svg-icons";
import { faFacebookF } from "@fortawesome/free-brands-svg-icons";
import { faPinterestP } from "@fortawesome/free-brands-svg-icons";
import { faArrowRightLong } from "@fortawesome/free-solid-svg-icons";
const FooterInner = () =>{
    return(
        <div>
{/* <!-- FOOTER SECTION START --> */}
<footer class="rv-inner-footer">
                <div class="container">
                    <div class="rv-8-footer-middle">
                        <div class="row g-4 justify-content-xl-between justify-content-center">
                            <div class="col-xl-3 col-lg-4 col-md-6 col-8 col-xxs-12">
                                <div class="rv-1-footer__about">
                                    <Link to="/"><img src={require('../assests/images/Alpha.png')} alt="Logo" class="logo" /></Link>
                                    <p class="rv-1-footer__about-txt">Alpha Infotech software development company is a specialized organization that designs, develops, and delivers custom software solutions to businesses and individuals.</p>

                                    <div class="rv-1-socials rv-inner-socials">
                                        <a href="https://www.facebook.com/Alphainfotech01"><FontAwesomeIcon icon={faFacebookF} /></a>
                                        <a href="https://twitter.com/alphainfot87536"><FontAwesomeIcon icon={faTwitter} /></a>
                                        <a href="https://www.instagram.com/alphainfotech.live"><FontAwesomeIcon icon={faInstagram} /></a>
                                        <a href="https://www.linkedin.com/company/79336937"><FontAwesomeIcon icon={faLinkedinIn} /></a>

 
                                    </div>
                                </div>
                            </div>

                            <div class="col-xl-2 col-lg-3 col-md-3 col-6 col-xxs-12">
                                <div class="rv-1-footer-widget rv-inner-footer-widget">
                                    <h5 class="rv-1-footer-widget__title">Useful Links</h5>
                                    <ul class="rv-8-footer-widget__links">
                                        <li><Link to="/about">About Us</Link></li>
                                        <li><Link to="/portfolio">Latest Work</Link></li>
                                        {/* <li><a href="#">Team Member</a></li> */}
                                        <li><Link to="/blog">Latest Blog</Link></li>
                                        <li><Link to="/contact">Contact Us</Link></li>
                                    </ul>
                                </div>
                            </div>

                            <div class="col-xl-2 col-lg-3 col-md-3 col-6 col-xxs-12">
                                <div class="rv-1-footer-widget rv-inner-footer-widget">
                                    <h5 class="rv-1-footer-widget__title">Our Services</h5>
                                    <ul class="rv-8-footer-widget__links">
                                    <li><Link to="/services/website-development">Website Development</Link></li>
                                    <li><Link to="/services/business-planning">Business Planning</Link></li>
                                    <li><Link to="/services/application-development">Application Development</Link></li>
                                    <li><Link to="/services/artificial-intelligence">Artificial Intelligence</Link></li>
                                    <li><Link to="/services/digital-marketing">Digital Marketing</Link></li>
                                    </ul>
                                </div>
                            </div>

                            <div class="col-xl-3 col-lg-5 col-md-6 col-8 col-xxs-12">
                                <div class="rv-1-footer-nwsltr">
                                    <h5 class="rv-1-footer-widget__title">Contact Us</h5>
                                    <form action="#"
                                        class="rv-6-footer-nwsltr__form  rv-8-footer-nwsltr__form rv-inner-footer-nwsltr__form">
                                        {/* <div class="nwsltr-top">
                                            <input type="email" required name="email" id="rv-8-subs-form"
                                                placeholder="Enter your Email..." />
                                            <button><FontAwesomeIcon icon={faArrowRightLong} /></button>
                                        </div> */}
                                        <div class="rv-6-footer-nwsltr__checkbox pt-0">
                                            {/* <input type="checkbox" required id="footer-nwsltr-checkbox" name="checkbox"
                                                value="1" />
                                            <label for="nwsltr-checkbox"> I agree to the <a href="#">Privacy Policy</a>.</label> */}
                                            <ul class="p-0 contact-address">
                                                <li>331, 3rd Floor Mansarovar Plaza Mansarovar, Jaipur 302020</li>
                                                <li>+91 80786 28108 <br /> +91 83868 00384</li>
                                                <li><a href="skype:cid.b8ece16698d7e7e?chat"><i class="fa-brands fa-skype"></i>  Alpha Infotech</a></li>
                                                <li>business@alphainfotech.live kunal@alphainfotech.live</li>
                                            </ul>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="rv-2-footer rv-8-footer-bottom">
                        <div class="row gy-3 align-items-center">
                            <div class="col-md-8">
                                <p class="rv-2-copyright rv-1-copyright mb-0 text-center text-md-start">&copy; 2024 Alpha Infotech. All Rights Reserved</p>
                            </div>
                            {/* <div class="col-md-4 text-center text-md-end">
                                <div class="rv-2-footer__nav">
                                    <a href="#">Privacy Policy</a>
                                    <a href="#">Term of Service</a>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </footer>
            {/* <!-- FOOTER SECTION END --> */}

        </div>
    )
}
export default FooterInner