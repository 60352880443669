import React from "react";
// import "./Index.css";
import "../App.css";
import { useRef } from "react";
import { Link } from "react-router-dom";
import "../component/Header";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay } from "@fortawesome/free-solid-svg-icons";
import { faArrowRightLong } from "@fortawesome/free-solid-svg-icons";
import { faShareNodes } from "@fortawesome/free-solid-svg-icons";
import { useState, useEffect } from "react";
import { InnerData } from "../component/InnerData";
import FooterInner from "../component/FooterInner";
import HeaderInner from "../component/HeaderInner";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import Fullgallery from "./Fullgallery";
import TawkMessengerReact from "@tawk.to/tawk-messenger-react";
import axios from "axios";
import { base } from "../api/constant";
import { useParams } from "react-router-dom";

const Home = () => {
    const [data, setData] = useState([])
    const [blogData, setBlogData] = useState([]);
    // useEffect(() => {
    //     // Create script element
    //     var Tawk_API = Tawk_API || {};
    //     var Tawk_LoadStart = new Date();
    //     (function () {
    //       var s1 = document.createElement("script");
    //       var s0 = document.getElementsByTagName("script")[0];
    //       s1.async = true;
    //       s1.src = "https://embed.tawk.to/YOUR_PROPERTY_ID/default";
    //       s1.charset = "UTF-8";
    //       s1.setAttribute("crossorigin", "*");
    //       s0.parentNode.insertBefore(s1, s0);
    //     })();
    //   }, []);

    useEffect(() => {
        fetchPortfolio();
        fetchblog();
        // Dynamically add Swiper CSS
        const link = document.createElement("link");
        link.rel = "stylesheet";
        link.href = `/css/swiper-bundle.min.css`;
        document.head.appendChild(link);

        // Dynamically add Swiper JS
        const script = document.createElement("script");
        script.src = "https://unpkg.com/swiper/swiper-bundle.min.js";
        script.async = true;
        document.body.appendChild(script);

        // Initialize Swiper once the script is loaded
        script.onload = () => {
            const Recent = new window.Swiper(".swiper-container2", {
                slidesPerView: 4,
                spaceBetween: 25,
                navigation: {
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev",
                },
                loop: true,
                autoplay: true,
                scrollbar: false,
                pagination: {
                    el: ".swiper-pagination",
                    clickable: true,
                },
                breakpoints: {
                    300: {
                        slidesPerView: 1,
                        spaceBetween: 20,
                    },
                    // when window width is >= 640px
                    640: {
                        slidesPerView: 2,
                        spaceBetween: 20,
                    },
                    // when window width is >= 768px
                    768: {
                        slidesPerView: 3,
                        spaceBetween: 30,
                    },
                    // when window width is >= 1024px
                    1024: {
                        slidesPerView: 4,
                        spaceBetween: 40,
                    },
                },
            });
            const testimonial = new window.Swiper(".swiper-container1", {
                slidesPerView: 1,
                spaceBetween: 25,
                navigation: {
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev",
                },
                autoplay: true,
                loop: true,
                scrollbar: false,
                pagination: {
                    el: ".swiper-pagination",
                    type: "fraction",
                    clickable: true,
                },
            });
            const blog = new window.Swiper(".swiper-container", {
                slidesPerView: 3,
                spaceBetween: 25,
                navigation: {
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev",
                },
                autoplay: true,
                loop: true,
                scrollbar: false,
                pagination: {
                    el: ".swiper-pagination",
                    clickable: true,
                },
                breakpoints: {
                    300: {
                        slidesPerView: 1,
                        spaceBetween: 20,
                    },
                    // when window width is >= 640px
                    640: {
                        slidesPerView: 1,
                        spaceBetween: 20,
                    },
                    // when window width is >= 768px
                    768: {
                        slidesPerView: 2,
                        spaceBetween: 30,
                    },
                    // when window width is >= 1024px
                    1024: {
                        slidesPerView: 3,
                        spaceBetween: 40,
                    },
                },
            });
        };

        // Cleanup: remove the script and link elements when the component unmounts
        return () => {
            document.body.removeChild(script);
            document.head.removeChild(link);
        };
    }, []); // Empty array ensures this effect runs only once

    const [isGalleryOpen, setIsGalleryOpen] = useState(false);
    const images = [
        {
            original: require("../assests/images/Software/Portfolio/rv-34-portfolio-img1.jpg"),
            //
            description: "Improve IT Security",
        },
        {
            original: require("../assests/images/Software/Portfolio/rv-34-portfolio-img2.jpg"),
            description: "Improve IT Security",
        },
        {
            original: require("../assests/images/Software/Portfolio/rv-34-portfolio-img3.jpg"),
            description: "Improve IT Security",
        },
        {
            original: require("../assests/images/Software/Portfolio/rv-34-portfolio-img4.jpg"),
            description: "Improve IT Security",
        },
        // Add more images as needed
    ];


       
      
        const fetchPortfolio = async () => {
          try {
            axios.get(base.portfolio).then((response) => {
              if (response.data.statusCode === 200) {
                setData(response.data.data)
                console.log('portfolio response 22222222222222222222', response.data.data);
              }
            });
          } catch (error) {
            console.log(error);
      
          }
        };
// portfolio api======================

    const fetchblog = async () => {
        try {
            axios.get(base.Blog).then((response) => {
                if (response.data.statusCode === 200) {
                    setBlogData(response.data.data)
                    console.log('abcdefghij', response.data.data);
                }
            });
        } catch (error) {
            console.log(error);

        }
    };
    // useEffect (() => {

    // },[])





    return (
        <div>



            <HeaderInner />



            {/* -----Banner--start------ */}
            <div class="rv-34-banner">
                <img
                    src={require("../assests/images/Software/banner/rv-34-banner.jpg")}
                    class="w-100"
                />
                <div class="rv-34-banner-overlay">
                    <div class="container">
                        <div class="row">
                            <div class="rv-34-banner-content">
                                <span class="rv-34-tag text-white wow fadeInUp">
                                    Create Your Partner in Web and App Development{" "}
                                </span>
                                <h1 class="rv-34-banner-title">
                                    Web and App Development<br></br>
                                    Company
                                </h1>
                                <p class="rv-34-banner-text ">
                                    We are a leading web and app development company, specializing
                                    in creating innovative and seamless digital
                                    solutions. Our team of experienced developers and designers
                                    combine cutting-edge technology with creative expertise to
                                    deliver exceptional websites and mobile applications that
                                    drive growth and engage users. With a focus on user-centric
                                    design and robust functionality, we bring your digital vision
                                    to life, helping you succeed in the dynamic online landscape.
                                    Trust us to transform your ideas into captivating digital
                                    experiences.
                                </p>

                                <div class="rv-34-banner-btns mt-40">
                                    <Link to="/portfolio" class="rv-34-btn mr-15 wow fadeInUp me-3">
                                        View Projects
                                        <i class="fa-solid fa-arrow-right-long ms-1"></i>
                                    </Link>

                                    <Link
                                        to="/contact"
                                        class="rv-34-btn wow fadeInUp"
                                        data-wow-delay="0.3s"
                                    >
                                        {" "}
                                        Get in Touch
                                        <i class="fa-solid fa-arrow-right-long ms-1 "></i>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/*  Banner section end  */}

            {/* Service section start  */}

            <div class="rv-34-service mt-120 ">
                <div class="container">
                    <div class="row">
                        <div class="rv-34-section-heading text-center mb-35">
                            <span class="rv-34-tag wow fadeInUp">What We Do?</span>
                            <h2
                                class="rv-34-section-title wow fadeInUp"
                                data-wow-delay="0.3s"
                            >
                                Some Exclusive Service
                            </h2>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-4 col-md-6">
                            <div class="rv-32-service-item rv-34-service-item wow fadeInUp">
                                <div class="rv-32-service-item__icon">
                                    <img
                                        src={require("../assests/images/Software/service/rv-34-icon1.png")}
                                        alt="icon"
                                    ></img>
                                </div>
                                <h5 class="rv-32-service-item__title">Website development</h5>
                                <p class="rv-32-service-item__text">
                                    Website development is a crucial aspect of an IT company's offerings. It involves creating and designing functional, visually appealing, and user-friendly websites that meet specific business objectives.
                                </p>
                                <div class="rv-34-service-item__overlay">
                                    <Link to="/services/website-development">
                                        <h5 class="rv-32-service-item__title">Website development</h5>
                                    </Link>

                                    <p class="rv-32-service-item__text">
                                        Website development is a crucial aspect of an IT company's offerings. It involves creating and designing functional, visually appealing, and user-friendly websites that meet specific business objectives.
                                    </p>

                                    <Link
                                        to="/services/website-development"
                                        class="rv-31-blog-item__content-readMore rv-34-view-link"
                                    >
                                        Read More <i class="fa-solid fa-arrow-right"></i>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div
                                class="rv-32-service-item rv-34-service-item wow fadeInUp"
                                data-wow-delay="0.25s"
                            >
                                <div class="rv-32-service-item__icon">
                                    <img
                                        src={require("../assests/images/Software/service/rv-34-icon2.png")}
                                        alt="icon"
                                    ></img>
                                </div>
                                <h5 class="rv-32-service-item__title">Business Planning</h5>
                                <p class="rv-32-service-item__text">
                                    Business planning a strategic roadmap that outlines the company's goals, objectives, and the steps to achieve them. It serves as a blueprint for decision-making, resource allocation, and risk management.
                                </p>
                                <div class="rv-34-service-item__overlay">
                                    <Link to="/services/business-planning">
                                        <h5 class="rv-32-service-item__title">Business Planning</h5>
                                    </Link>
                                    <p class="rv-32-service-item__text">
                                        Business planning a strategic roadmap that outlines the company's goals, objectives, and the steps to achieve them. It serves as a blueprint for decision-making, resource allocation, and risk management.
                                    </p>
                                    <Link
                                        to="/services/business-planning"
                                        class="rv-31-blog-item__content-readMore rv-34-view-link"
                                    >
                                        Read More <i class="fa-solid fa-arrow-right"></i>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div
                                class="rv-32-service-item rv-34-service-item wow fadeInUp"
                                data-wow-delay="0.35s"
                            >
                                <div class="rv-32-service-item__icon">
                                    <img
                                        src={require("../assests/images/Software/service/rv-34-icon3.png")}
                                        alt="icon"
                                    ></img>
                                </div>
                                <h5 class="rv-32-service-item__title">Application development</h5>
                                <p class="rv-32-service-item__text">
                                    Application development is a core service offered by many IT companies. It involves creating software applications that solve specific problems or meet specific needs. This can include developing mobile apps, web applications, desktop software
                                </p>
                                <div class="rv-34-service-item__overlay">
                                    <Link to="/services/application-development">
                                        <h5 class="rv-32-service-item__title">Application development</h5>
                                    </Link>
                                    <p class="rv-32-service-item__text">
                                        Application development is a core service offered by many IT companies. It involves creating software applications that solve specific problems or meet specific needs. This can include developing mobile apps, web applications, desktop software
                                    </p>
                                    <Link
                                        to="/services/application-development"
                                        class="rv-31-blog-item__content-readMore rv-34-view-link"
                                    >
                                        Read More <i class="fa-solid fa-arrow-right"></i>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div
                                class="rv-32-service-item rv-34-service-item wow fadeInUp"
                                data-wow-delay="0.45s"
                            >
                                <div class="rv-32-service-item__icon">
                                    <img
                                        src={require("../assests/images/Software/service/rv-34-icon4.png")}
                                        alt="icon"
                                    ></img>
                                </div>
                                <h5 class="rv-32-service-item__title">Artificial intelligence (AI)</h5>
                                <p class="rv-32-service-item__text">
                                    Artificial intelligence (AI) is a rapidly growing field that involves creating intelligent agents capable of performing tasks that typically require human intelligence. IT companies often incorporate AI into their services to provide innovative solutions and improve efficiency.
                                </p>
                                <div class="rv-34-service-item__overlay">
                                    <Link to="/services/artificial-intelligence">
                                        <h5 class="rv-32-service-item__title">Artificial intelligence (AI)</h5>
                                    </Link>
                                    <p class="rv-32-service-item__text">
                                        Artificial intelligence (AI) is a rapidly growing field that involves creating intelligent agents capable of performing tasks that typically require human intelligence. IT companies often incorporate AI into their services to provide innovative solutions and improve efficiency.
                                    </p>
                                    <Link
                                        to="/services/artificial-intelligence"
                                        class="rv-31-blog-item__content-readMore rv-34-view-link"
                                    >
                                        Read More <i class="fa-solid fa-arrow-right"></i>
                                    </Link>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6">
                            <div
                                class="rv-32-service-item rv-34-service-item wow fadeInUp"
                                data-wow-delay="0.55s"
                            >
                                <div class="rv-32-service-item__icon">
                                    <img
                                        src={require("../assests/images/Software/service/rv-34-icon5.png")}
                                        alt="icon"
                                    ></img>
                                </div>
                                <h5 class="rv-32-service-item__title">Digital Marketing</h5>
                                <p class="rv-32-service-item__text">
                                    Digital marketing is a crucial aspect of IT companies, encompassing a wide range of strategies to reach and engage potential customers online. It involves leveraging digital channels to promote products or services, build brand awareness.
                                </p>
                                <div class="rv-34-service-item__overlay">
                                    <Link to="/services/digital-marketing">
                                        <h5 class="rv-32-service-item__title">Digital Marketing</h5>
                                    </Link>

                                    <p class="rv-32-service-item__text">
                                        Digital marketing is a crucial aspect of IT companies, encompassing a wide range of strategies to reach and engage potential customers online. It involves leveraging digital channels to promote products or services, build brand awareness.
                                    </p>
                                    <Link
                                        to="/services/digital-marketing"
                                        class="rv-31-blog-item__content-readMore rv-34-view-link"
                                    >
                                        Read More <i class="fa-solid fa-arrow-right"></i>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div
                                class="rv-32-service-item rv-34-service-item wow fadeInUp"
                                data-wow-delay="0.65s"
                            >
                                <div class="rv-32-service-item__icon">
                                    <img
                                        src={require("../assests/images/Software/service/rv-34-icon2.png")}
                                        alt="icon"
                                    ></img>
                                </div>
                                <h5 class="rv-32-service-item__title">Graphic designers</h5>
                                <p class="rv-32-service-item__text">
                                    Graphic designers in IT companies play a crucial role in creating visually appealing and effective digital assets. They work closely with developers, marketers, and other team members to design interfaces, logos, branding materials, and other visual elements.
                                </p>
                                <div class="rv-34-service-item__overlay">
                                    <Link to="/services/digital-marketing">
                                        <h5 class="rv-32-service-item__title">Graphic designers</h5>
                                    </Link>

                                    <p class="rv-32-service-item__text">
                                        Graphic designers in IT companies play a crucial role in creating visually appealing and effective digital assets. They work closely with developers, marketers, and other team members to design interfaces, logos, branding materials, and other visual elements.
                                    </p>
                                    <Link
                                        to="/services/digital-marketing"
                                        class="rv-31-blog-item__content-readMore rv-34-view-link"
                                    >
                                        Read More <i class="fa-solid fa-arrow-right"></i>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Service section start  */}

            {/* About section start   */}

            <section class="rv-34-abt">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="rv-34-abt-imgs wow slideInLeft">
                                <img
                                    src={require("../assests/images/Software/about/it-company1.png")}
                                    class="rv-34-big-img"
                                    alt="speaker"
                                />

                                <img
                                    src={require("../assests/images/Software/about/rv-34-abt-1.png")}
                                    class="rv-34-sm-img"
                                    alt="speaker"
                                />
                            </div>
                        </div>

                        <div class="col-lg-6">
                            <div class="rv-34-abt-content">
                                <div class="rv-34-section-heading mb-35">
                                    <span class="rv-34-tag">Welcome to Alpha infotech
                                    </span>
                                    <h2 class="rv-34-section-title">
                                        How to IT Services work?
                                        {/* <br /> Services For Your Firm. */}
                                    </h2>
                                    <p class="rv-34-abt-content__text">
                                        We offer a comprehensive range of IT development services to empower your organization in the digital age. From software development and customization to system integration and application support, our team of skilled professionals delivers tailored solutions that align with your specific business objectives. Partner with us to harness the power of technology and drive sustainable growth for your business.
                                    </p>

                                    <ul>
                                        <li>• Protect your business​ from being in market trend.</li>
                                        <li>• Optimize your systems to approved standards.</li>
                                        <li>• Empower your employees of creativity.</li>
                                        <li>• Create brand of your digital works.</li>
                                    </ul>


                                    <div class="rv-34-abt-content__btns mt-40 wow fadeInUp">
                                        <Link to="/about" class="rv-34-btn mr-20">
                                            Read More
                                            <FontAwesomeIcon icon={faArrowRightLong} />
                                        </Link>

                                        <a
                                            href="https://www.youtube.com/embed/PBDlbSAddAI?si=v8WM4F7YcsE4MMtS"
                                            class="rv-34-vdo-btn my-video-links"
                                            data-autoplay="true"
                                            data-vbtype="video"
                                            data-maxwidth="800px"
                                        >
                                            <FontAwesomeIcon icon={faPlay} />
                                        </a>
                                        <span>How it Works</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>

            {/* About  section end   */}

            {/* <!-- Portfolio section start  --> */}

            <section class="rv-34-gallary ">
                <div class="container">
                    <div class="row">
                        <div class="rv-34-section-heading d-flex justify-content-md-between justify-content-center align-items-center mb-35">
                            <div class="rv-34-section-heading-left">
                                <span class="rv-34-tag wow fadeInUp">Recent Projects</span>
                                <h2
                                    class="rv-34-section-title wow fadeInUp"
                                    data-wow-delay="0.3s"
                                >
                                    Our Latest Case Studies
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="rv-34-portfolio-slider swiper-container2">
                    <div class="rv-34-porfolio-slider-controller  "></div>
                    {isGalleryOpen && (
                        <Fullgallery
                            images={images}
                            onClose={() => setIsGalleryOpen(false)}
                        />
                    )}
                    <div class="swiper-wrapper">
                        {data.map((item) => (
                            <div class="swiper-slide">
                                <div class="rv-33-gallary-item rv-34-portfolio-item">
                                    <img src={item.bannerImage?.url} alt="Person" />
                                    <div class="rv-33-gallary-item__overlay">
                                        {/* <a href={require('../assests/images/Software/Portfolio/rv-34-portfolio-img1.jpg')}
                                            class="rv-33-gallary-item__overlay-icon my-image-links venobox" data-gall="gallery01" >
                                        <i class="fa-solid fa-plus"></i>
                                    </a> */}

                                        <a
                                            href="#"
                                            onClick={(e) => {
                                                e.preventDefault(); // Prevent default anchor behavior
                                                setIsGalleryOpen(true);
                                            }}
                                            className="rv-33-gallary-item__overlay-icon my-image-links venobox"
                                        >
                                            <i className="fa-solid fa-plus"></i>
                                        </a>

                                        <div class="rv-33-gallary-item__overlay-title">
                                            {/* <span>{item.title}</span> */}
                                            {/* <Link to={`/portfolio/${item.nameID}`} class="rv-34-gallary-item__overlay-title">{item.heading}
                                            </Link> */}
                                            <Link to={`/portfolio/${item?._id}`} class="rv-34-gallary-item__overlay-title">{item?.title}
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}

                        {/* <div class="swiper-slide">
                <div class="rv-33-gallary-item rv-34-portfolio-item">
                    <img src={require('../assests/images/Software/Portfolio/rv-34-portfolio-img2.jpg')} alt="Person"/>
                    <div class="rv-33-gallary-item__overlay">
                        <a href="assets/img/Software/Portfolio/rv-34-portfolio-img2.jpg"
                            class="rv-33-gallary-item__overlay-icon  my-image-links" data-gall="gallery01">
                            <i class="fa-solid fa-plus"></i>
                        </a>
                        <div class="rv-33-gallary-item__overlay-title">
                            <span>Development</span>
                            <a href="project-details.html" class="rv-34-gallary-item__overlay-title">Improve IT
                                Security</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="swiper-slide">
                <div class="rv-33-gallary-item rv-34-portfolio-item">
                    <img src={require('../assests/images/Software/Portfolio/rv-34-portfolio-img3.jpg')} alt="Person"/>
                    <div class="rv-33-gallary-item__overlay">
                        <a href="assets/img/Software/Portfolio/rv-34-portfolio-img3.jpg"
                            class="rv-33-gallary-item__overlay-icon my-image-links" data-gall="gallery01">
                            <i class="fa-solid fa-plus"></i>
                        </a>
                        <div class="rv-33-gallary-item__overlay-title">
                            <span>Development</span>
                            <a href="project-details.html" class="rv-34-gallary-item__overlay-title">Improve IT
                                Security</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="swiper-slide">
                <div class="rv-33-gallary-item rv-34-portfolio-item">
                    <img src={require('../assests/images/Software/Portfolio/rv-34-portfolio-img4.jpg')} alt="Person"/>
                    <div class="rv-33-gallary-item__overlay">
                        <a href="assets/img/Software/Portfolio/rv-34-portfolio-img4.jpg"
                            class="rv-33-gallary-item__overlay-icon my-image-links" data-gall="gallery01">
                            <i class="fa-solid fa-plus"></i>
                        </a>
                        <div class="rv-33-gallary-item__overlay-title">
                            <span>Development</span>
                            <a href="project-details.html" class="rv-34-gallary-item__overlay-title">Improve IT
                                Security</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="swiper-slide">
                <div class="rv-33-gallary-item rv-34-portfolio-item">
                    <img src={require('../assests/images/Software/Portfolio/rv-34-portfolio-img1.jpg')} alt="Person"/>
                    <div class="rv-33-gallary-item__overlay">
                        <a href="assets/img/Software/Portfolio/rv-34-portfolio-img1.jpg"
                            class="rv-33-gallary-item__overlay-icon my-image-links" data-gall="gallery01">
                            <i class="fa-solid fa-plus"></i>
                        </a>
                        <div class="rv-33-gallary-item__overlay-title">
                            <span>Development</span>
                            <a href="project-details.html" class="rv-34-gallary-item__overlay-title">Improve IT
                                Security</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="swiper-slide">
                <div class="rv-33-gallary-item rv-34-portfolio-item">
                    <img src={require('../assests/images/Software/Portfolio/rv-34-portfolio-img2.jpg')} alt="Person"/>
                    <div class="rv-33-gallary-item__overlay">
                        <a href="assets/img/Software/Portfolio/rv-34-portfolio-img2.jpg"
                            class="rv-33-gallary-item__overlay-icon my-image-links" data-gall="gallery01">
                            <i class="fa-solid fa-plus"></i>
                        </a>
                        <div class="rv-33-gallary-item__overlay-title">
                            <span>Development</span>
                            <a href="project-details.html" class="rv-34-gallary-item__overlay-title">Improve IT
                                Security</a>
                        </div>
                    </div>
                </div>
            </div> */}
                    </div>
                </div>
            </section>

            {/* <!-- Portfolio section end  --> */}

            {/* <!-- Testimonial section start  --> */}

            <div class="rv-34-testimonial-sec ">
                <div class="container">
                    <div class="row">
                        <div class="rv-34-section-heading text-center mb-35">
                            <span class="rv-34-tag wow fadeInUp">Testimonials</span>
                            <h2 class="rv-34-section-title">
                                Customer Feedback <br /> <span>&</span> Comments.
                            </h2>
                        </div>

                        <div class="rv-34-testimonial-swiper-container swiper-container1">
                            <div class="swiper-wrapper">
                                {InnerData.testimonial.map((item) => (
                                    <div class="swiper-slide">
                                        <div class="rv-28-client-review">
                                            <div class="rv-28-testimonial-stars ">
                                                <img
                                                    src={require("../assests/images/rv-27-testimoial-star.png")}
                                                    alt="star"
                                                />
                                                <img
                                                    src={require("../assests/images/rv-27-testimoial-star.png")}
                                                    alt="star"
                                                />
                                                <img
                                                    src={require("../assests/images/rv-27-testimoial-star.png")}
                                                    alt="star"
                                                />
                                                <img
                                                    src={require("../assests/images/rv-27-testimoial-star.png")}
                                                    alt="star"
                                                />
                                                <img
                                                    src={require("../assests/images/rv-27-testimoial-star.png")}
                                                    alt="star"
                                                />
                                            </div>
                                            <p>{item.comments}</p>
                                            <div class="rv-28-author">
                                                <h6>{item.name}</h6>
                                                <span>{item.subtitle}</span>
                                            </div>
                                            <img
                                                src={require("../assests/images/rv-colon.png")}
                                                class="rv-28-colon"
                                                alt="colon"
                                            />
                                        </div>
                                    </div>
                                ))}
                                {/* <div class="swiper-slide">
                                    <div class="rv-28-client-review">
                                        <div class="rv-28-testimonial-stars">
                                            <img src={require('../assests/images/rv-27-testimoial-star.png')} alt="star" />
                                            <img src={require('../assests/images/rv-27-testimoial-star.png')} alt="star" />
                                            <img src={require('../assests/images/rv-27-testimoial-star.png')} alt="star" />
                                            <img src={require('../assests/images/rv-27-testimoial-star.png')} alt="star" />
                                            <img src={require('../assests/images/rv-27-testimoial-star.png')} alt="star" />
                                        </div>
                                        <p>
                                            “Morbi consectetur elementum purus mattis cursus purus metus iaculis sagittis.
                                            Vestibulum
                                            molestie bibendum turpis
                                            luctus sem lacinia Quisque amet velit amet dui hendrerit ultricies id ipsum
                                            Mauris sit amet
                                            Suspends potent quis commodo
                                            ligula vestibulu lacinia est”
                                        </p>
                                        <div class="rv-28-author">
                                            <h6>Diansi Disouse</h6>
                                            <span>Happy Client</span>
                                        </div>
                                        <img src={require('../assests/images/rv-colon.png')} class="rv-28-colon" alt="colon" />
                                    </div>
                                </div>
                                <div class="swiper-slide">
                                    <div class="rv-28-client-review">
                                        <div class="rv-28-testimonial-stars">
                                            <img src={require('../assests/images/rv-27-testimoial-star.png')} alt="star" />
                                            <img src={require('../assests/images/rv-27-testimoial-star.png')} alt="star" />
                                            <img src={require('../assests/images/rv-27-testimoial-star.png')} alt="star" />
                                            <img src={require('../assests/images/rv-27-testimoial-star.png')} alt="star" />
                                            <img src={require('../assests/images/rv-27-testimoial-star.png')} alt="star" />
                                        </div>
                                        <p>
                                            “Morbi consectetur elementum purus mattis cursus purus metus iaculis sagittis.
                                            Vestibulum
                                            molestie bibendum turpis
                                            luctus sem lacinia Quisque amet velit amet dui hendrerit ultricies id ipsum
                                            Mauris sit amet
                                            Suspends potent quis commodo
                                            ligula vestibulu lacinia est”
                                        </p>
                                        <div class="rv-28-author">
                                            <h6>Dylan Meringue</h6>
                                            <span>Happy Customer</span>
                                        </div>
                                        <img src={require('../assests/images/rv-colon.png')} class="rv-28-colon" alt="colon" />
                                    </div>
                                </div> */}
                            </div>
                            <div class="rv-28-testimonial-controller swiper-pagination-bullets swiper-pagination-horizontal swiper-pagination">
                                {/* <button class="rv-28-cf-arrow-btn-left"><i class="fa-thin fa-arrow-left-long"></i></button>
                            <span class="rv-28-testimonial-count"></span>
                            <button class="rv-28-cf-arrow-btn-right"><i class="fa-thin fa-arrow-right-long"></i></button> */}
                            </div>
                        </div>
                    </div>

                    <div class="rv-28-clients">
                        <img
                            src={require("../assests/images/rv-28-client1.jpg")}
                            class="rv-28-client__1"
                            alt="client"
                        />
                        <img
                            src={require("../assests/images/rv-28-client2.jpg")}
                            class="rv-28-client__2"
                            alt="client"
                        />
                        <img
                            src={require("../assests/images/rv-28-client3.jpg")}
                            class="rv-28-client__3"
                            alt="client"
                        />
                        <img
                            src={require("../assests/images/rv-28-client4.jpg")}
                            class="rv-28-client__4"
                            alt="client"
                        />
                        <img
                            src={require("../assests/images/rv-28-client5.jpg")}
                            class="rv-28-client__5"
                            alt="client"
                        />
                        <img
                            src={require("../assests/images/rv-28-client6.jpg")}
                            class="rv-28-client__6"
                            alt="client"
                        />
                    </div>
                </div>
            </div>

            {/* <!-- Testimonial section end  -- */}

            {/* <!-- Team section start  --> */}

            {/* <section class="rv-34-team mb-120">
                <div class="container">
                    <div class="row">
                        <div
                            class="rv-34-section-heading d-md-flex justify-content-md-between justify-content-center  align-items-center mb-30">
                            <div class="rv-34-section-heading-left">
                                <span class="rv-34-tag wow fadeInUp">Team Members</span>
                                <h2 class="rv-34-section-title wow fadeInUp" data-wow-delay="0.3s">Meet Our Leadership</h2>
                            </div>
                            <a href="#" class="rv-31-blog-item__content-readMore rv-34-view-link wow fadeInUp"
                                data-wow-delay="0.3s">View All Members
                                <FontAwesomeIcon icon={faArrowRightLong} />
                            </a>
                        </div>
                    </div>
                    <div class="row">
                        {teamMembers.slice(0, 3).map((item) => (
                            <div class="col-lg-4 col-md-6 col-sm-12">
                                <div class="rv-34-team-member">
                                    <div class="rv-34-team-member__img ">
                                        <img src={item.image} class="w-100 " alt="team-member" />
                                    </div>
                                    <div class="rv-34-team-member__overlay">
                                        <div class="rv-34-team-member__info">
                                            <a href="#" class="d-block ">
                                                <h6 class="rv-34-team-member__info-name">{item.name}</h6>
                                            </a>
                                            <span class="rv-34-team-member__info-title">{item.title}</span>
                                        </div>
                                        <div class="rv-34-team-member__socials">
                                            <a href="#"><i class="fa-brands fa-facebook-f"></i></a>
                                            <a href="#"><i class="fa-brands fa-linkedin-in"></i></a>
                                            <a href="#"><i class="fa-brands fa-twitter"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))} */}
            {/* <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="rv-34-team-member">
                                <div class="rv-34-team-member__img ">
                                    <img src={require('../assests/images/Software/team/rv-34-team2.jpg')} class="w-100 " alt="team-member" />
                                </div>
                                <div class="rv-34-team-member__overlay">
                                    <div class="rv-34-team-member__info">
                                        <a href="#" class="d-block ">
                                            <h6 class="rv-34-team-member__info-name">Ravi O'Leigh</h6>
                                        </a>
                                        <span class="rv-34-team-member__info-title">CHEF TECHNOLOGY</span>
                                    </div>
                                    <div class="rv-34-team-member__socials">
                                        <a href="#"><i class="fa-brands fa-facebook-f"></i></a>
                                        <a href="#"><i class="fa-brands fa-linkedin-in"></i></a>
                                        <a href="#"><i class="fa-brands fa-twitter"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="rv-34-team-member">
                                <div class="rv-34-team-member__img ">
                                    <img src={require('../assests/images/Software/team/rv-34-team3.jpg')} class="w-100 " alt="team-member" />
                                </div>
                                <div class="rv-34-team-member__overlay">
                                    <div class="rv-34-team-member__info">
                                        <a href="#" class="d-block ">
                                            <h6 class="rv-34-team-member__info-name">Ravi O'Leigh</h6>
                                        </a>
                                        <span class="rv-34-team-member__info-title">CHEF TECHNOLOGY</span>
                                    </div>
                                    <div class="rv-34-team-member__socials">
                                        <a href="#"><i class="fa-brands fa-facebook-f"></i></a>
                                        <a href="#"><i class="fa-brands fa-linkedin-in"></i></a>
                                        <a href="#"><i class="fa-brands fa-twitter"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div> */}
            {/* </div>
                </div>
            </section> */}

            {/* <!-- Team section end  --> */}

            {/* <!-- Blog section start  --> */}

            <section class="rv-34-blog pt-120 pb-90">
                <div class="container">

                    <div class="row">
                        <div class="rv-34-section-heading text-center mb-35">
                            <span class="rv-34-tag wow fadeInUp">Blog & News</span>
                            <h2
                                class="rv-34-section-title wow fadeInUp"
                                data-wow-delay="0.3s"
                            >
                                Our Latest Findings
                            </h2>
                        </div>
                    </div>

                    <div class="rv-34-blog-slider swiper-container">
                        <div class="swiper-wrapper">
                            {blogData.map((item) => (
                                <div key={item.id} class="swiper-slide">
                                    <div class="rv-34-blog-item card">
                                        <div class="rv-34-blog-item__img">
                                            {/* <Link to={`/blog/${item.nameID}`}><img src={item.images} class="w-100" alt="blog page" /></Link> */}
                                            <Link to={`/blog/${item._id}`}><img src={item.bannerImage.url} class="w-100" alt="blog page" /></Link>

                                        </div>

                                        <div class="rv-34-blog-item__content">

                                            {/* <Link to={`/blog/${item.nameID}`} class="rv-34-blog-item__content-title">{item.heading}</Link> */}
                                            <Link to={`/blog/${item._id}`} class="rv-34-blog-item__content-title">{item.title}</Link>


                                            <hr />
                                            <div class="rv-34-blog-item-tags">
                                                <div class=" rv-34-blog-item-date_comments m-0 ">
                                                    <i class="fa-light fa-calendar-days"></i>
                                                    <span class="me-3 ">March 16, 2024</span>
                                                    <br />
                                                    {/* <i class="fa-sharp fa-regular fa-comments"></i>
                                                    <span class="me-1 ">2 Comments</span> */}
                                                </div>
                                                <div class="rv-34-blog-share-btn">
                                                    <FontAwesomeIcon icon={faShareNodes} />

                                                    <div class="rv-34-blog-share-btn-container">
                                                        <a
                                                            href="#"
                                                            class="rv-34-blog-share-btn-container__item"
                                                        >
                                                            <i class="fa-brands fa-facebook-f"></i>
                                                        </a>
                                                        <a
                                                            href="#"
                                                            class="rv-34-blog-share-btn-container__item"
                                                        >
                                                            <i class="fa-brands fa-twitter"></i>
                                                        </a>
                                                        <a
                                                            href="#"
                                                            class="rv-34-blog-share-btn-container__item"
                                                        >
                                                            <i class="fa-brands fa-instagram"></i>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <Link to={`/blog/${item._id}`} class="rv-34-btn">
                                            {item.title}
                                        </Link>
                                    </div>
                                </div>
                            ))}
{/* ================================================================================================= */}
                            {/* <div class="swiper-slide">
                                <div class="rv-34-blog-item card">
                                    <div class="rv-34-blog-item__img">
                                        <a href="blog-details.html">
                                            <img src={require('../assests/images/Software/blog/rv-34-blog2.jpg')} class="w-100 "
                                                alt="blog page" />
                                        </a>
                                    </div>
                                    <div class="rv-34-blog-item__content">
                                        <a href="blog-details.html" class="rv-34-blog-item__content-title">
                                            Foremost 14 Tech Stacks in<br />
                                            Software Building.
                                        </a>
                                        <hr />
                                        <div class="rv-34-blog-item-tags">
                                            <div class=" rv-34-blog-item-date_comments m-0 ">
                                                <i class="fa-light fa-calendar-days"></i>
                                                <span class="me-3 ">March 16, 2023</span><br />
                                                <i class="fa-sharp fa-regular fa-comments"></i>
                                                <span class="me-1 ">2 Comments</span>
                                            </div>
                                            <div class="rv-34-blog-share-btn">
                                                <FontAwesomeIcon icon={faShareNodes} />

                                                <div class="rv-34-blog-share-btn-container">
                                                    <a href="#" class="rv-34-blog-share-btn-container__item"><i
                                                        class="fa-brands fa-facebook-f"></i></a>
                                                    <a href="#" class="rv-34-blog-share-btn-container__item"><i
                                                        class="fa-brands fa-twitter"></i></a>
                                                    <a href="#" class="rv-34-blog-share-btn-container__item"><i
                                                        class="fa-brands fa-instagram"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <a href="blog-details.html" class="rv-34-btn">Meeting</a>
                                </div>
                            </div>
                            <div class="swiper-slide">
                                <div class="rv-34-blog-item card">
                                    <div class="rv-34-blog-item__img">
                                        <a href="blog-details.html">
                                            <img src={require('../assests/images/Software/blog/rv-34-blog3.jpg')} class="w-100 "
                                                alt="blog page" />
                                        </a>
                                    </div>
                                    <div class="rv-34-blog-item__content">
                                        <a href="blog-details.html" class="rv-34-blog-item__content-title">
                                            Foremost 14 Tech Stacks in<br />
                                            Software Building.
                                        </a>
                                        <hr />
                                        <div class="rv-34-blog-item-tags">
                                            <div class=" rv-34-blog-item-date_comments m-0 ">
                                                <i class="fa-light fa-calendar-days"></i>
                                                <span class="me-3 ">March 16, 2023</span><br />
                                                <i class="fa-sharp fa-regular fa-comments"></i>
                                                <span class="me-1 ">2 Comments</span>
                                            </div>
                                            <div class="rv-34-blog-share-btn">
                                                <FontAwesomeIcon icon={faShareNodes} />
                                                <div class="rv-34-blog-share-btn-container">
                                                    <a href="#" class="rv-34-blog-share-btn-container__item"><i
                                                        class="fa-brands fa-facebook-f"></i></a>
                                                    <a href="#" class="rv-34-blog-share-btn-container__item"><i
                                                        class="fa-brands fa-twitter"></i></a>
                                                    <a href="#" class="rv-34-blog-share-btn-container__item"><i
                                                        class="fa-brands fa-instagram"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <a href="blog-details.html" class="rv-34-btn">Optimizing</a>
                                </div>
                            </div>
                            <div class="swiper-slide">
                                <div class="rv-34-blog-item card">
                                    <div class="rv-34-blog-item__img">
                                        <a href="blog-details.html">
                                            <img src={require('../assests/images/Software/blog/rv-34-blog2.jpg')} class="w-100 "
                                                alt="blog page" />
                                        </a>
                                    </div>
                                    <div class="rv-34-blog-item__content">
                                        <a href="blog-details.html" class="rv-34-blog-item__content-title">
                                            Foremost 14 Tech Stacks in<br />
                                            Software Building.
                                        </a>
                                        <hr />
                                        <div class="rv-34-blog-item-tags">
                                            <div class=" rv-34-blog-item-date_comments m-0 ">
                                                <i class="fa-light fa-calendar-days"></i>
                                                <span class="me-3 ">March 16, 2023</span><br />
                                                <i class="fa-sharp fa-regular fa-comments"></i>
                                                <span class="me-1 ">2 Comments</span>
                                            </div>
                                            <div class="rv-34-blog-share-btn">
                                                <FontAwesomeIcon icon={faShareNodes} />

                                                <div class="rv-34-blog-share-btn-container">
                                                    <a href="#" class="rv-34-blog-share-btn-container__item"><i
                                                        class="fa-brands fa-facebook-f"></i></a>
                                                    <a href="#" class="rv-34-blog-share-btn-container__item"><i
                                                        class="fa-brands fa-twitter"></i></a>
                                                    <a href="#" class="rv-34-blog-share-btn-container__item"><i
                                                        class="fa-brands fa-instagram"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <a href="blog-details.html" class="rv-34-btn">Meeting</a>
                                </div>
                            </div>
                            <div class="swiper-slide">
                                <div class="rv-34-blog-item card">
                                    <div class="rv-34-blog-item__img">
                                        <a href="blog-details.html">
                                            <img src={require('../assests/images/Software/blog/rv-34-blog3.jpg')} class="w-100 "
                                                alt="blog page" />
                                        </a>
                                    </div>
                                    <div class="rv-34-blog-item__content">
                                        <a href="blog-details.html" class="rv-34-blog-item__content-title">
                                            Foremost 14 Tech Stacks in<br />
                                            Software Building.
                                        </a>
                                        <hr />
                                        <div class="rv-34-blog-item-tags">
                                            <div class=" rv-34-blog-item-date_comments m-0 ">
                                                <i class="fa-light fa-calendar-days"></i>
                                                <span class="me-3 ">March 16, 2023</span><br />
                                                <i class="fa-sharp fa-regular fa-comments"></i>
                                                <span class="me-1 ">2 Comments</span>
                                            </div>
                                            <div class="rv-34-blog-share-btn">
                                                <FontAwesomeIcon icon={faShareNodes} />

                                                <div class="rv-34-blog-share-btn-container">
                                                    <a href="#" class="rv-34-blog-share-btn-container__item"><i
                                                        class="fa-brands fa-facebook-f"></i></a>
                                                    <a href="#" class="rv-34-blog-share-btn-container__item"><i
                                                        class="fa-brands fa-twitter"></i></a>
                                                    <a href="#" class="rv-34-blog-share-btn-container__item"><i
                                                        class="fa-brands fa-instagram"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <a href="blog-details.html" class="rv-34-btn">Optimizing</a>
                                </div>
                            </div>
                            <div class="swiper-slide">
                                <div class="rv-34-blog-item card">
                                    <div class="rv-34-blog-item__img">
                                        <a href="blog-details.html">
                                            <img src={require('../assests/images/Software/blog/rv-34-blog1.jpg')} class="w-100 "
                                                alt="blog page" />
                                        </a>
                                    </div>
                                    <div class="rv-34-blog-item__content">
                                        <a href="blog-details.html" class="rv-34-blog-item__content-title">
                                            Foremost 14 Tech Stacks in<br />
                                            Software Building.
                                        </a>
                                        <hr />
                                        <div class="rv-34-blog-item-tags">
                                            <div class=" rv-34-blog-item-date_comments m-0 ">
                                                <i class="fa-light fa-calendar-days"></i>
                                                <span class="me-3 ">March 16, 2023</span><br />
                                                <i class="fa-sharp fa-regular fa-comments"></i>
                                                <span class="me-1 ">2 Comments</span>
                                            </div>
                                            <div class="rv-34-blog-share-btn">
                                                <FontAwesomeIcon icon={faShareNodes} />

                                                <div class="rv-34-blog-share-btn-container">
                                                    <a href="#" class="rv-34-blog-share-btn-container__item"><i
                                                        class="fa-brands fa-facebook-f"></i></a>
                                                    <a href="#" class="rv-34-blog-share-btn-container__item"><i
                                                        class="fa-brands fa-twitter"></i></a>
                                                    <a href="#" class="rv-34-blog-share-btn-container__item"><i
                                                        class="fa-brands fa-instagram"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <a href="blog-details.html" class="rv-34-btn">Development</a>
                                </div>
                            </div> */}
                        </div>

                        <div class="rv-34-blog-controller mt-45 swiper-pagination-bullets swiper-pagination-horizontal swiper-pagination"></div>
                    </div>
                </div>
            </section>

            {/* <!-- Blog section end  --> */}

            {/* <!-- Footer section start  --> */}

            <FooterInner />

            {/* <!-- Footer section end  --> */}
        </div>
    );
};

export default Home;
