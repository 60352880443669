import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { useState, useEffect } from "react";
import anime from "animejs";
import $ from 'jquery';
const HeaderInner = () => {
    const anime = require('animejs');
    const [isSticky, setIsSticky] = useState(false);
    const handleScroll = () => {
        if (window.scrollY > 50) {
            setIsSticky(true);
        } else {
            setIsSticky(false);
        }
    };
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);





    useEffect(() => {
        // FIXED HEADER =====
        const toFixHeaders = document.querySelectorAll(".to-be-fixed");
        const hamburgerBtn = document.querySelector("#rv-1-header-mobile-menu-btn");
        const mobileMenu = document.querySelector(".rv-1-header-nav__sidebar");
        window.addEventListener("scroll", () => {
            const activeMobileMenu = mobileMenu.classList.contains("active");
            toFixHeaders.forEach(toFixHeader => {
                if (window.scrollY > 100 && !activeMobileMenu) {
                    toFixHeader.classList.add("fixed");
                    document.body.style.paddingTop = toFixHeader.getBoundingClientRect().height + 'px';
                } else {
                    toFixHeader.classList.remove("fixed");
                    document.body.style.paddingTop = 0;
                }
            });
        });

        //===== FIXED HEADER


        // =========  responsive menu ===================

        const hamburgerCloseBtn = document.querySelector(".sidebar-close-btn");

        if ($(".rv-1-header-nav__sidebar").length > 0) {
            hamburgerBtn.addEventListener("click", () => {
                document.querySelector(".rv-1-header-nav__sidebar").classList.add("active");

                toFixHeaders.forEach(toFixHeader => {
                    if (toFixHeader.classList.contains("fixed")) {
                        toFixHeader.classList.remove("fixed");
                    }
                });
            });
        }
        if ($(".rv-1-header-nav__sidebar").length > 0) {
            hamburgerCloseBtn.addEventListener("click", () => {
                document.querySelector(".rv-1-header-nav__sidebar").classList.remove("active");
                document.body.parentElement.style.overflowY = "scroll";
            });
        }

        // mobile menu dropdown ===============
        function attachDropdownListeners() {
            const topMenus = document.querySelectorAll(".rv-1-header__nav>ul>li");

            topMenus.forEach(topMenu => {
                // Remove any existing event listeners to prevent duplicates
                topMenu.replaceWith(topMenu.cloneNode(true));
            });

            const updatedTopMenus = document.querySelectorAll(".rv-1-header__nav>ul>li");

            updatedTopMenus.forEach(topMenu => {
                if (window.innerWidth < 992) {
                    topMenu.addEventListener('click', () => {
                        topMenu.classList.toggle("rv-dropdown-active");

                        // Remove the class from siblings
                        let siblings = Array.from(topMenu.parentNode.children).filter(child => child !== topMenu);
                        siblings.forEach(sibling => sibling.classList.remove("rv-dropdown-active"));
                    });
                }
            });
        }

        // Run the function on page load
        window.addEventListener('DOMContentLoaded', attachDropdownListeners);

        // Run the function on window resize
        window.addEventListener('resize', attachDropdownListeners);


        // =============  responsive menu ======================
    }, []);


    //   useEffect(() => {
    //     const scrollTriggerScript = document.createElement('script');
    // scrollTriggerScript.src = 'https://cdnjs.cloudflare.com/ajax/libs/gsap/3.11.0/ScrollTrigger.min.js';
    // scrollTriggerScript.async = true;
    // document.body.appendChild(scrollTriggerScript);

    //     const gsapScript = document.createElement('script');
    // gsapScript.src = 'https://cdnjs.cloudflare.com/ajax/libs/gsap/3.11.0/gsap.min.js';
    // gsapScript.async = true;
    // document.body.appendChild(gsapScript);

    //     const animeScript = document.createElement('script');
    //     animeScript.src = 'https://cdnjs.cloudflare.com/ajax/libs/animejs/3.2.1/anime.min.js';
    //     animeScript.async = true;
    //     document.body.appendChild(animeScript);

    //     const jQueryScript = document.createElement('script');
    //     jQueryScript.src = 'https://code.jquery.com/jquery-3.6.0.min.js';
    //     jQueryScript.async = true;
    //     document.body.appendChild(jQueryScript);

    //     const customScript  = document.createElement('script');
    //     customScript .src = `${process.env.PUBLIC_URL}/main.js`; // Referencing main.js
    //     customScript .async = true;
    //     document.body.appendChild(customScript);



    //     return () => {

    //         document.body.removeChild(gsapScript);
    //         document.body.removeChild(animeScript);
    //       document.body.removeChild(jQueryScript);
    //   document.body.removeChild(customScript); // Cleanup script when component unmounts
    //     };
    //   }, []);
    return (
        <div>
            {/* <!-- HEADER SECTION STARTS HERE --> */}

            <header class="rv-1-header rv-inner-header to-be-fixed" className={isSticky ? 'header sticky' : 'header'} >
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-3 col-4 col-xxs-6">
                            <div class="rv-1-logo imgLOGO">
                                <Link to="/"><img src={require('../assests/images/Alpha1.png')} alt="logo" class="rv-1-logo" /></Link>

                            </div>
                        </div>

                        {/* <!-- nav menu --> */}
                        <div class="col-md-7 order-2 order-lg-1">
                            <div class="rv-1-header-nav__sidebar">
                                <div class="sidebar-heading d-lg-none d-flex align-items-center justify-content-between">
                                    <Link to="/" class="logo-container"><img src={require('../assests/images/Alpha1.png')} alt="logo" /></Link>

                                    <button
                                        class="rv-3-def-btn rv-1-header-mobile-menu-btn rv-inner-mobile-menu-btn sidebar-close-btn"><i
                                            class="fa-regular fa-xmark"></i></button>
                                </div>

                                <div class="rv-1-header__nav rv-inner-header__nav">
                                    <ul class="justify-content-end ">
                                        <li>
                                            <Link to="/">Home</Link>
                                            {/* <ul class="sub-menu">
                                                <li><a href="#">Software</a></li>
                                                <li><a href="#">App landing</a></li>
                                                <li><Link to="/seo">Seo</Link></li>
                                                <li><a href="#">IT Security</a></li>
                                                <li><Link to="/">IT Service</Link></li>

                                            </ul> */}
                                        </li>
                                        <li class="services">
                                            <a href="#">Services</a>
                                            <ul class="sub-menu">
                                                <li className="servicBX"><div class="service_head">
                                                    <h3>Services</h3>
                                                    <p>Full-spectrum IT services served by a host of trending technologies and
                                                        resources. Crafting engaging web identities, mobile-first products, dynamic
                                                        scripting interfaces, and rich internet applications across all major
                                                        operating formats and tech platforms. </p>
                                                    <div class="menu_btn rv-inner-header-right-btns">
                                                        <a href="#" class="btn btn-danger">Get Started</a>
                                                    </div>
                                                </div></li>
                                               
                                                <li className="serviceBX2"><div class="border-cls">
                                             <div class="menu_img">
                                                <div class="submenu-title ">
                                                   <h3>
                                                      <a class="dropdown-item mobileapp" href="#">
                                                         <span>Mobile App Development</span>
                                                      </a>
                                                   </h3>
                                                </div>
                                             </div>
                                             <div class="megamenu-cls">
                                                <a class="dropdown-item" href="#">
                                                   Android</a>
                                                <a class="dropdown-item" href="#">
                                                   iPhone</a>
                                                <a class="dropdown-item" href="#">
                                                   Shopify App Development</a>
                                                <a class="dropdown-item" href="#">
                                                   Flutterflow Development</a>
                                             </div>
                                          </div>
                                          <div class="border-cls">
                                             <div class="menu_img">
                                                <div class="submenu-title">
                                                   <h3>
                                                      <a class="dropdown-item mobileapp" href="#">
                                                         <span>Web Development</span>
                                                      </a>
                                                   </h3>
                                                </div>
                                             </div>
                                             <div class="megamenu-cls">
                                                <a class="dropdown-item" href="#">Full
                                                   Stack Development</a>
                                                <a class="dropdown-item" href="#">Mean
                                                   Stack Development</a>
                                                <a class="dropdown-item" href="#">PHP
                                                   Web Development</a>
                                                <a class="dropdown-item" href="#">ASP.Net
                                                   Development</a>
                                                <a class="dropdown-item" href="#">AngularJS
                                                   Web Development</a>
                                                <a class="dropdown-item" href="#">ReactJS
                                                   Development</a>
                                                <a class="dropdown-item" href="#">NodeJS
                                                   Development</a>
                                                <a class="dropdown-item" href="#">Symfony
                                                   Development</a>
                                                   <a class="dropdown-item" href="#">UI/UX Design Agency</a>
                                             </div>
                                          </div></li>
                                         
                                                <li className="serviceBX2"><div class="border-cls">
                                             <div class="menu_img">
                                                <div class="submenu-title">
                                                   <h3>
                                                      <a class="dropdown-item mobileapp" href="#">
                                                         <span>Cross Platform Development</span>
                                                      </a>
                                                   </h3>
                                                </div>
                                             </div>
                                             <div class="megamenu-cls">
                                                <a class="dropdown-item" href="#">React
                                                   Native</a>
                                                <a class="dropdown-item" href="#">Flutter</a>
                                                <a class="dropdown-item" href="#">Ionic</a>
                                             </div>
                                          </div>
                                          <div class="border-cls">
                                             <div class="menu_img">
                                                <div class="submenu-title">
                                                   <h3>
                                                      <a class="dropdown-item mobileapp" href="#">
                                                         <span>Trending Technologies</span>
                                                      </a>
                                                   </h3>
                                                </div>
                                             </div>
                                             <div class="megamenu-cls">
                                                <a class="dropdown-item" href="#">Artificial
                                                   Intelligence</a>
                                                <a class="dropdown-item" href="#">IoT
                                                   App Development</a>
                                                <a class="dropdown-item" href="#">
                                                   Cloud Computing Solutoins</a>
                                                <a class="dropdown-item" href="#">Wearable
                                                   App Development</a>
                                                <a class="dropdown-item" href="#t">Blockchain
                                                   Application Development</a>
                                                <a class="dropdown-item" href="#">Cryptocurrency
                                                   App Development</a>
                                                <a class="dropdown-item" href="#">ICO
                                                   Development Company</a>
                                                <a class="dropdown-item" href="#">Salesforce
                                                   Development Company</a>
                                                <a class="dropdown-item" href="#">Digital
                                                   Marketing Agency</a>
                                             </div>
                                          </div></li>

                                            </ul>



                                        </li>
                                        <li>
                                            <Link to="/about">About</Link>
                                        </li>

                                        <li>
                                            <Link to="/portfolio">Portfolio</Link>
                                        </li>


                                        {/* <li>
                                            <a href="#">Shop</a>
                                            <ul class="sub-menu">
                                            <li><a href="#">Shop</a></li>
                                                <li><a href="#">Shop with Sidebar</a></li>
                                                <li><Link to="/productd">Product Details</Link></li>
                                            </ul>
                                        </li> */}

                                        <li>
                                            <Link to="/blog">Blog</Link>
                                            {/* <ul class="sub-menu">
                                                <li><Link to="/blog">Blog</Link></li>
                                                <li><Link to="/blogdetail">Blog Details</Link></li>
                                            </ul> */}
                                        </li>

                                        {/* <li>
                                            <Link to="/contact">Contact</Link>
                                        </li> */}
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-2 col-8 col-xxs-6 text-end order-1 order-lg-2">
                            <div class="d-flex justify-content-lg-center justify-content-end">
                                <div class="rv-inner-header-right-btns">
                                    {/* <a href="login.html"><FontAwesomeIcon icon={faUser} />Login</a> */}
                                    <Link to="/contact" class="d-sm-inline-block d-none">let's talk</Link>
                                </div>
                                <button
                                    class="rv-1-header-mobile-menu-btn rv-3-def-btn rv-inner-mobile-menu-btn d-lg-none d-inline-block"
                                    id="rv-1-header-mobile-menu-btn"><i class="fa-regular fa-bars"></i></button>
                            </div>
                        </div>
                    </div>
                </div>
            </header>

            {/* <!-- HEADER SECTION ENDS HERE --> */}






        </div>
    )
}
export default HeaderInner