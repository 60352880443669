import logo from './logo.svg';
import './App.css';
import Home from './home/Home';
import About from './about/About';
import Contact from './contact/Contact';
import Services from './services/Services';
import { Helmet } from 'react-helmet';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import ServiceD from './services_detail/ServiceD';
import Projects from './portfolio/Portfolio';
import ProjectD from './project_details/PortfolioD';
import Eror from './eror_404/Eror';
import Seo from './seo/Seo';
import ProductD from './product_detail/ProductD'; 
import BlogDetail from './blogDetails/BlogDetail';
import Blog from './blogg/Blog';
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';

 


// import owl from './custom_js/vendor/owl-carousel/owl.carousel.min.js';







function App() {
  return (
    <>
      {/* <Helmet ><script src={`${owl}`} type='text/javascript'></script></Helmet> */}
      <Router >
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/services" element={<Services />} />
          <Route path="/services/:id" element={<ServiceD />} />
          <Route path="/portfolio" element={<Projects />} />
          <Route path="/portfolio/:id" element={<ProjectD />} />
          <Route path="/eror" element={<Eror />} />
          <Route path="/seo" element={<Seo />} />
          <Route path="/productd" element={<ProductD />} />
          <Route path="/blog/:id" element={<BlogDetail/>}/>
          <Route path="/blog" element={<Blog/>}/>
      

        </Routes>

      </Router>
      <TawkMessengerReact
                propertyId="64a3cc79cc26a871b0263742"
                widgetId="default"/>

    </>

  );
}

export default App;
