
import React, { useEffect, useState } from 'react'
import "../App.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHouse } from "@fortawesome/free-solid-svg-icons";
import { Link } from 'react-router-dom';
import { faPaperPlane } from "@fortawesome/free-regular-svg-icons"
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { faLink } from "@fortawesome/free-solid-svg-icons";
import HeaderInner from '../component/HeaderInner';
import FooterInner from '../component/FooterInner';
import { InnerData } from '../component/InnerData';
import axios from 'axios';
import { base } from '../api/constant';



const Projects = () => {
  const [data, setData] = useState([])

  const fetchPortfolio = async () => {
    try {
      axios.get(base.portfolio).then((response) => {
        if (response.data.statusCode === 200) {
          setData(response.data.data)
          console.log('portfolio response 22222222222222222222', response.data.data);
        }
      });
    } catch (error) {
      console.log(error);

    }
  };


  useEffect(() => {
    fetchPortfolio();
  }, [])


  return (
    <div> {/* <!-- HEADER SECTION STARTS HERE --> */}

      <HeaderInner />

      {/* <!-- HEADER SECTION ENDS HERE --> */}



      {/* <!-- BREADCRUMB STARTS HERE --> */}
      <div class="rv-breadcrumb pt-120 pb-120 portfolio-page">
        <div class="imageOverlay"></div>
        <div class="container breadBox">
          <h1 class="rv-breadcrumb__title Aboutsec">Portfolio</h1>

          <ul class="rv-breadcrumb__nav d-flex justify-content-center">
            <li><Link to="/"><FontAwesomeIcon icon={faHouse} /> Home</Link></li>
            <li class="current-page"><span class="dvdr"> &#47;</span><span class="dvdr">Portfolio</span></li>
          </ul>
        </div>
      </div>

      {/* <!-- BREADCRUMB ENDS HERE --> */}

      {/* <!-- PROJECTS SECTION START --> */}
      <section class="rv-9-projects pt-60 pb-60">
        <div class="container">
          <div class="rv-inner-projects" data-aos="fade-up">
            <div class="row g-xl-4 g-3 row-cols-lg-3 row-cols-sm-2 row-cols-1">

            {data.map((item) => (
                <div class="col">
                  <div class="rv-3-project rv-9-project rv-inner-project">
                    <div class="rv-3-project__img">
                      <img src={item.bannerImage?.url} alt="Project Image" />
                      <div class="rv-3-project__actions">
                        <div class="rv-3-project__actions">
                          <button class="quick-view"><FontAwesomeIcon icon={faMagnifyingGlass} /></button>
                          {/* <Link to={`/portfolio/${item.nameID}`}><FontAwesomeIcon icon={faLink} /></Link> */}
                          <Link to={`/portfolio/${item?._id}`}><FontAwesomeIcon icon={faLink} /></Link>
                        </div>
                      </div>
                    </div>

                    <div class="rv-9-project__txt">
                      <span class="rv-3-project__sub-title">Application</span>
                      <h5 class="rv-9-project__title">
                        {/* <Link to={`/portfolio/${item.nameID}`}>{item.heading}</Link> */}
                        <Link to={`/portfolio/${item?._id}`}>{item?.title}</Link>
                        </h5>
                    </div>
                  </div>
                </div>

              ))}


            </div>
          </div>
        </div>
      </section>
      {/* <!-- PROJECTS SECTION END --> */}



      {/* <!-- CTA SECTION START --> */}
      <section class="rv-14-cta rv-inner-cta rv-section-spacing">
        <div class="container">
          <div class="row g-4 align-items-center">
            <div class="col-lg-6">
              <div class="rv-14-cta__txt">
                <h6 class="rv-1-section__sub-title rv-text-anime">Our Newsletter</h6>
                <h2 class="rv-1-section__title rv-text-anime mb-0">Become a Member For Unique Updates!</h2>
              </div>
            </div>
            <div class="col-lg-6">
              <form action="#" class="rv-6-footer-nwsltr__form rv-14-nwsltr__form">
                <div class="nwsltr-left">
                  <input type="email" name="email" placeholder="Enter your Email..." />
                  <div class="rv-6-footer-nwsltr__checkbox rv-14-nwsltr__checkbox">
                    <input type="checkbox" id="nwsltr-checkbox" name="checkbox" value="1" />
                    <label for="nwsltr-checkbox"> I agree to the <a href="#">Privacy Policy</a>.</label>
                  </div>
                </div>
                <button><FontAwesomeIcon icon={faPaperPlane} /> <span class="txt">Subscribe</span></button>
              </form>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- CTA SECTION END --> */}


      {/* <!-- FOOTER SECTION START --> */}
      <FooterInner />
      {/* <!-- FOOTER SECTION END --> */}




    </div>
  )
}

export default Projects