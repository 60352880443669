import React from "react";
import "../App.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHouse } from "@fortawesome/free-solid-svg-icons";
import { Link } from 'react-router-dom';
import HeaderInner from "../component/HeaderInner";
import FooterInner from "../component/FooterInner";
import { useState } from "react";
import emailjs from "emailjs-com";

const About = () => {

    const [toSend, setToSend] = useState({
        from_name: '',
        to_name: '',
        message: '',
        reply_to: '',
        subjects: '', // Add subjects here
    });

    const handleChange = (e) => {
        setToSend({ ...toSend, [e.target.name]: e.target.value });
    };

    const onSubmit = (e) => {
        e.preventDefault();

        // Make sure EmailJS is configured correctly with these IDs
        emailjs
            .send(
                'service_j7ct3oqzz',
                'template_dbiv9pnzz',
                toSend,
                'a8bIvv6FDajiYpFHizz'
            )
            .then((response) => {
                console.log('SUCCESS!', response.status, response.text);
                // Optionally add some user feedback here
                alert('Message sent successfully!');
                // Reload the page after the alert is acknowledged
                window.location.reload();
            })
            .catch((err) => {
                console.log('FAILED...', err);
                // Optionally handle the error (show a message, etc.)
                alert('Failed to send message. Please try again later.');
            });
    };



    // const [formData, setFormData] = useState({
    //     name: '',
    //     email: '',
    //     subjects: '',
    //     message: ''
    // });

    // const handleChange = (e) => {
    //     setFormData({ ...formData, [e.target.name]: e.target.value });
    // };

    // const handleSubmit = async (e) => {
    //     e.preventDefault();
    //     try {
    //         const response = await fetch('http://localhost:5000/send', {
    //             method: 'POST',
    //             headers: {
    //                 'Content-Type': 'application/json',
    //             },
    //             body: JSON.stringify(formData),
    //         });
    //         const result = await response.json();
    //         alert('Message sent successfully: ' + result);
    //     } catch (error) {
    //         console.error('Error sending message:', error);
    //         alert('Failed to send message.');
    //     }
    // };

    return (
        <div>
            {/* <!-- HEADER SECTION STARTS HERE --> */}
            <HeaderInner />
            {/* <!-- HEADER SECTION ENDS HERE --> */}


            {/* <!-- BREADCRUMB STARTS HERE --> */}

            <div class="rv-breadcrumb pt-120 pb-120 contact-page">
                <div class="imageOverlay"></div>
                <div class="container breadBox">
                    <h1 class="rv-breadcrumb__title Aboutsec">Contact</h1>

                    <ul class="rv-breadcrumb__nav d-flex justify-content-center">
                        <li><Link to="/"><FontAwesomeIcon icon={faHouse} /> Home</Link></li>
                        <li class="current-page"><span class="dvdr"> &#47;</span><span class="dvdr">Contact</span></li>
                    </ul>
                </div>
            </div>
            {/* <!-- BREADCRUMB ENDS HERE --> */}


            {/* <!-- CONTACT SECTION START --> */}
            <section class="rv-2-contact rv-inner-contact rv-section-spacing" data-aos="fade-up">
                <div class="container">

                    <div class="rv-inner-contact-info-cards">
                        <div class="flexBox">
                            <div class="rv-inner-contact-info">
                                <div class="rv-inner-contact-info__heading">
                                    <div class="rv-inner-contact-info__icon">
                                        <img src={require('../assests/images/phone-call.png')} />
                                    </div>
                                    <h5 class="rv-inner-contact-info__title">Contact Numbers</h5>
                                </div>

                                <div class="rv-inner-contact-info__bottom">
                                    <ul class="rv-5-footer-timings">
                                        <li><a href="tel:8078628108">+91 80786 28108</a></li>
                                        <li><a href="tel:8386800384">+91 83868 00384</a></li>
                                        <li><a href="skype:cid.b8ece16698d7e7e?chat"><i class="fa-brands fa-skype"></i>  Alpha Infotech</a></li>
                                    </ul>
                                </div>
                            </div>

                            <div class="rv-inner-contact-info">
                                <div class="rv-inner-contact-info__heading">
                                    <div class="rv-inner-contact-info__icon">
                                        <img src={require('../assests/images/email.png')} />
                                    </div>
                                    <h5 class="rv-inner-contact-info__title">Email Address</h5>
                                </div>

                                <div class="rv-inner-contact-info__bottom">
                                    <ul class="rv-5-footer-timings">
                                        <li><a href="mailto:info@revel.com">kunal@alphainfotech.live</a></li>
                                        <li><a href="mailto:test@revel.com">business@alphainfotech.live</a></li>
                                    </ul>
                                </div>
                            </div>

                        </div>

                        <div className="flexBox">
                            <div class="rv-inner-contact-info">
                                <div class="rv-inner-contact-info__heading">
                                    <div class="rv-inner-contact-info__icon">
                                        <img src={require('../assests/images/email.png')} />
                                    </div>
                                    <h5 class="rv-inner-contact-info__title">Address</h5>
                                </div>

                                <div class="rv-inner-contact-info__bottom">
                                    <ul class="rv-5-footer-timings">
                                        <li>331, 3rd Floor Mansarovar Plaza Mansarovar, Jaipur 302020</li>

                                    </ul>
                                </div>
                            </div>

                            <div class="rv-inner-contact-info">
                                <div class="rv-inner-contact-info__heading">
                                    <div class="rv-inner-contact-info__icon">
                                        <img src={require('../assests/images/clock.png')} />
                                    </div>
                                    <h5 class="rv-inner-contact-info__title">Hours of Operation</h5>
                                </div>

                                <div class="rv-inner-contact-info__bottom">
                                    <ul class="rv-5-footer-timings">
                                        <li>
                                            <span class="key">Monday - Friday : </span><br />
                                            <span class="value">08:30 am - 10:00 pm</span>
                                        </li>
                                        <li>
                                            <span class="key">Saturday - Sunday : </span>
                                            <span class="value">10:30 am - 08:00 pm</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        {/*flex */}

                    </div>

                    <div class="row gy-3 gy-sm-4">
                        {/* <!-- contact form --> */}
                        <div class="col-xxl-8 col-lg-7">
                            <div class="rv-2-contact__txt rv-inner-contact__txt">
                                <h3 class="rv-2-contact-form-title">Let's Connect.</h3>
                                {/* <form action="#" class="rv-2-contact__form rv-inner-contact__form">
                                    <div class="row">
                                        <div class="col-sm-6">
                                            <input type="text" required name="name" id="rv-2-contact-name"
                                                placeholder="Your Name" />
                                        </div>

                                        <div class="col-sm-6">
                                            <input type="email" required name="email" id="rv-2-contact-email"
                                                placeholder="Email" />
                                        </div>
                                        <div class="col-12">
                                            <select name="subjects" required id="rv-2-contact-subject">
                                                <option value="Selects Subject" hidden>Select Subject</option>
                                                <option value="Project Buy">Project Buy</option>
                                                <option value="Custom Project">Custom Project</option>
                                                <option value="Partnership Offer">Partnership Offer</option>
                                                <option value="others">Others</option>
                                            </select>
                                        </div>
                                        <div class="col-12">
                                            <textarea name="message" required id="rv-2-contact-message"
                                                placeholder="Message"></textarea>
                                        </div>
                                        <div class="col-12">
                                            <button type="submit">Send Message</button>
                                        </div>
                                    </div>
                                </form> */}
                                <form onSubmit={onSubmit} className="rv-2-contact__form rv-inner-contact__form">
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <input
                                                type="text"
                                                required
                                                name="from_name"
                                                placeholder="Your Name"
                                                value={toSend.from_name}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className="col-sm-6">
                                            <input
                                                type="email"
                                                required
                                                name="reply_to"
                                                placeholder="Email"
                                                value={toSend.reply_to}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className="col-12">
                                            <select
                                                name="subjects"
                                                required
                                                value={toSend.subjects}
                                                onChange={handleChange}
                                            >
                                                <option value="Selects Subject" hidden>Select Subject</option>
                                                <option value="Project Buy">Project Buy</option>
                                                <option value="Custom Project">Custom Project</option>
                                                <option value="Partnership Offer">Partnership Offer</option>
                                                <option value="others">Others</option>
                                            </select>
                                        </div>
                                        <div className="col-12">
                                            <textarea
                                                name="message"
                                                required
                                                placeholder="Message"
                                                value={toSend.message}
                                                onChange={handleChange}
                                            ></textarea>
                                        </div>
                                        <div className="col-12">
                                            <button type="submit">Send Message</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>

                        {/* <!-- map --> */}
                        <div class="col-xxl-4 col-lg-5">
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d529.130320103406!2d75.76670367676773!3d26.8542515546826!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x396db5f090c8fbbd%3A0x1c5e6abada3e61ac!2sAlpha%20Infotech!5e0!3m2!1sen!2sbd!4v1727346023474!5m2!1sen!2sbd" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>

                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- CONTACT SECTION END --> */}

            {/* <!-- FOOTER SECTION START --> */}
            <FooterInner />
            {/* <!-- FOOTER SECTION END --> */}





        </div>
    )
}

export default About