
import React, { useState } from 'react'
import "../App.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHouse } from "@fortawesome/free-solid-svg-icons"
import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import $ from 'jquery';
import HeaderInner from '../component/HeaderInner';
import FooterInner from '../component/FooterInner';
import { useParams } from 'react-router-dom';



const ServiceD = () => {
    const [pageName, setPageName] = useState('');
    const [title, setTitle] = useState('');
    const [image, setImage] = useState('');
    const [detail1, setDetail1] = useState('');
    const [detail2, setDetail2] = useState('');
    const [title1, setTitle1] = useState('');
    const [title2, setTitle2] = useState('');
    const [title3, setTitle3] = useState('');
    const [title4, setTitle4] = useState('');
    const [title5, setTitle5] = useState('');
    const [title6, setTitle6] = useState('');
    const [blogData, setBlogData] = useState({
        contentType1: [],
        contentType2: [],
        contentType3: [],
        contentType4: [],
        contentType5: [],
        contentType6: [],
    });
    // const [blogData, setBlogData] = useState({
    //     keyStages: [],
    //     typesofApplications: [],
    // });


    const { id } = useParams();
    useEffect(() => {
        // Example of jQuery code inside useEffect
        (function ($) {
            "use strict";
            const accordionItems = document.querySelectorAll(".rv-accordion-item");

            accordionItems.forEach((accordionItem) => {
                accordionItem.onclick = () => {
                    const openedItems = document.querySelector(".rv-accordion-item.open");
                    if (accordionItem.classList.contains("open")) {
                        openedItems.classList.remove("open")
                    } else {
                        accordionItem.classList.toggle("open");
                        if (openedItems) {
                            openedItems.classList.remove("open")
                        }
                    }
                }
            })
        })($);



        if (id === 'website-development') {
            setPageName('Website Development');
            setTitle('Website Development');
            setImage(require('../assests/images/img-4.jpg'));
            setDetail1(`Website development is the process of creating a website. It involves designing, building, and maintaining a website to serve a specific purpose, such as providing information, selling products, or offering services.website development is a complex process that requires a combination of technical skills and creative thinking. By following a structured approach and utilizing the right tools and technologies, you can create a successful website that meets your business objectives.`);
            setTitle1(`Key Components of Website Development`);
            setTitle2(`Types of Websites`);
            setTitle3('Website Development Tools and Technologies');
            setBlogData({
                contentType1: [
                    { span: 'Planning and Strategy:', content: `This phase involves defining the website's goals, target audience, and content structure. It helps ensure that the website is aligned with the overall business objectives.` },
                    { span: 'Design:', content: `The design phase focuses on creating the visual elements of the website, including layout, color scheme, typography, and overall aesthetics. A well-designed website is visually appealing and easy to navigate.` },
                    { span: 'Development:', content: `In this phase, the website's functionality is built using programming languages like HTML, CSS, JavaScript, and potentially others. The development team creates the structure, layout, and interactivity of the website.` },
                    { span: 'Content Creation:', content: `High-quality content is essential for a successful website. This includes text, images, videos, and other media that informs and engages visitors.` },
                    { span: 'Testing:', content: `Once the website is developed, it undergoes rigorous testing to ensure it functions properly on different devices, browsers, and operating systems.` },
                    { span: 'Launch:', content: ` After testing, the website is launched and made accessible to the public.` },
                    { span: 'Maintenance:', content: `Websites require ongoing maintenance to keep them up-to-date, secure, and functioning optimally. This includes regular updates, security patches, and performance optimization.` },
                ],
                contentType2: [
                    { span: 'Static Websites:', content: `These websites have fixed content that doesn't change frequently. They are often used for simple websites with basic information.` },
                    { span: 'Dynamic Websites:', content: `These websites can generate content on the fly based on user interactions or database information. They are more flexible and can be used for complex applications like e-commerce stores and content management systems.` },
                    { span: 'E-commerce Websites', content: `These websites are designed to sell products or services online. They typically include features like shopping carts, payment gateways, and product catalogs.` },
                    { span: 'Portfolio Websites:', content: `These websites showcase an individual's or company's work, such as designers, artists, or freelancers.` },
                    { span: 'Blog Websites', content: `These websites are used to publish regular articles or blog posts on a specific topic.` },
                ],
                contentType3: [
                    { span: 'HTML (Hypertext Markup Language):', content: `The structure of a webpage.` },
                    { span: 'CSS (Cascading Style Sheets):', content: `The styling of a webpage, including colors, fonts, and layout.` },
                    { span: 'JavaScript:', content: `A programming language that adds interactivity to a webpage.` },
                    { span: 'Content Management Systems (CMS):', content: `Software that makes it easier to create and manage website content, such as WordPress, Drupal, and Joomla.` },
                    { span: 'Web Development Frameworks:', content: `Tools that provide a structure for building web applications, such as React, Angular, and Vue.js.` },
                ]
            });

        }
        else if (id === 'application-development') {
            setPageName('Application Development');
            setTitle('Application Development');
            setImage(require('../assests/images/img-4.jpg'));
            setDetail1(`44It appears that your query is still quite broad. If you're
                referring to information about an agency, I'd be happy to help if you provide more details
                or clarify the type of agency you're interested in. For example, are you looking for
                information about a creative agency, marketing agency, design agency, or another type of
                agency?`);
            setTitle1(`Key Stages of Application Development:`);
            setTitle2(`Types of Applications:`);

            setBlogData({
                contentType1: [
                    { span: 'Requirement Gathering:', },
                    { content: `Understanding the client's needs and objectives.` },
                    { content: `Identifying the problem the application will solve.` },
                    { content: `Defining the scope and features of the application.` },
                    { span: 'Design:', },
                    { content: `Creating a blueprint for the application, including its architecture, user interface, and database structure.` },
                    { content: `Designing the user experience to ensure it is intuitive and easy to use.` },
                    { content: `Developing wireframes and prototypes to visualize the application's layout and functionality.` },
                    { span: 'Development:', },
                    { content: `Writing the code using programming languages like Java, Python, C++, or JavaScript.` },
                    { content: `Building the application's backend and frontend components.` },
                    { content: `Integrating APIs and third-party services.` },
                    { span: 'Testing:', },
                    { content: `Thoroughly testing the application to identify and fix bugs or errors.` },
                    { content: `Conducting usability testing to ensure the application meets user expectations.` },
                    { content: `Performing security testing to protect against vulnerabilities.` },
                    { span: 'Deployment:', },
                    { content: `Deploying the application to its intended environment, such as a web server, app store, or desktop.` },
                    { content: `Configuring servers and databases to support the application.` },
                    { span: 'Maintenance:', },
                    { content: `Providing ongoing support and updates to the application.` },
                    { content: `Addressing bugs or issues that arise after deployment.` },
                    { content: `Implementing new features or enhancements as needed.` },

                ],
                contentType2: [
                    { span: 'Mobile Apps:', content: `Designed for smartphones and tablets, including native apps for iOS and Android.` },
                    { span: 'Web Applications:', content: `Accessed through a web browser, often using technologies like HTML, CSS, and JavaScript.` },
                    { span: 'Desktop Applications:', content: `Installed on a computer, providing standalone functionality.` },
                    { span: 'Enterprise Applications:', content: `Designed for large organizations to manage various business processes.` },
                ],
                contentType3: [

                ]

            });


        }
        else if (id === 'business-planning') {
            setPageName('Business Planning');
            setTitle('Business Planning');
            setImage(require('../assests/images/img-4.jpg'));
            setDetail1(`Business planning is a crucial aspect of any IT company, regardless of its size or specialization. A well-crafted plan provides a roadmap for growth, helps secure funding, and ensures that the company is aligned with its goals.`);
            setTitle1(`Key Components of an IT Business Plan`);
            setTitle2(`Specific Considerations for IT Companies`);
            setTitle3(`Tips for Effective Business Planning`)
            setDetail2(`Alpha Infotech is a dynamic and innovative digital marketing agency dedicated to helping businesses thrive in the digital age. With a team of experienced professionals, we offer a comprehensive range of services designed to enhance online visibility, drive traffic, and generate leads. `);
            setTitle4(`Our Services:`);
            setTitle5(`Our Approach:`);
            setTitle6(`Why Choose Us:`);
            setBlogData({
                contentType1: [
                    { span: 'Executive Summary:', content: `A concise overview of the entire plan, highlighting the company's mission, vision, products or services, target market, and financial projections.` },
                    { span: 'Company Description:', content: ` A detailed explanation of the company's history, structure, and legal status.` },
                    { span: 'Market Analysis:', content: `A thorough examination of the industry, target market, competitors, and trends.` },
                    { span: 'Product/Service Description:', content: `A clear and compelling description of the company's offerings, including their unique selling points and benefits to customers.` },
                    { span: 'Marketing and Sales Strategy:', content: `A detailed plan for reaching the target market, promoting products or services, and generating sales.` },
                    { span: 'Operations Plan:', content: ` A description of the company's operational processes, including production, distribution, and customer service.` },
                    { span: 'Management and Organization:', content: `A profile of the company's management team, their experience, and the organizational structure.` },
                    { span: 'Financial Projections:', content: `A detailed financial forecast, including income statements, balance sheets, and cash flow statements.` },
                    { span: 'Funding Request:', content: `If seeking external funding, a clear outline of the amount needed, how it will be used, and the terms of repayment.` },

                ],
                contentType2: [
                    { span: 'Technology Roadmap:', content: `A plan for the development and adoption of new technologies to stay competitive.` },
                    { span: 'Intellectual Property:', content: `A strategy for protecting the company's intellectual property, such as patents, copyrights, and trademarks.` },
                    { span: 'Talent Acquisition and Retention:', content: `A plan for attracting and retaining skilled IT professionals.` },
                    { span: 'Cybersecurity:', content: `A comprehensive cybersecurity plan to protect the company's data and systems.` },
                    { span: 'Scalability:', content: `A strategy for scaling the business to meet increasing demand.` },
                ],
                contentType3: [
                    { span: 'Research:', content: `Conduct thorough research on the industry, market trends, and competitors.` },
                    { span: 'Collaboration:', content: `Involve key stakeholders in the planning process to ensure buy-in and alignment.` },
                    { span: 'Flexibility:', content: `Be prepared to adapt the plan as circumstances change.` },
                    { span: 'Regular Review:', content: `Regularly review and update the plan to keep it relevant.` },

                ],
                contentType4: [
                    { span: 'Search Engine Optimization (SEO):', content: `We optimize websites to rank higher in search engine results pages (SERPs), increasing organic traffic and visibility.` },
                    { span: 'Pay-Per-Click (PPC) Advertising:', content: ` We manage effective PPC campaigns on platforms like Google Ads and Bing Ads to target specific audiences and drive immediate results.` },
                    { span: 'Social Media Marketing:', content: `We create engaging social media content, build online communities, and manage social media campaigns to increase brand awareness and reach.` },
                    { span: 'Content Marketing:', content: `We develop high-quality content, such as blog posts, articles, and videos, to attract and engage your target audience.` },
                    { span: 'Email Marketing:', content: `We design and execute targeted email marketing campaigns to nurture leads, drive sales, and build customer loyalty.` },
                    { span: 'Website Design and Development:', content: `We create visually appealing and user-friendly websites that are optimized for both search engines and user experience.` },
                    { span: 'Analytics and Reporting:', content: `We track and analyze key performance indicators (KPIs) to measure the success of your digital marketing efforts and provide actionable insights.` },

                ],
                contentType5: [
                    { span: 'Data-Driven Strategies:', content: `We rely on data and analytics to inform our strategies and ensure maximum ROI.` },
                    { span: 'Customized Solutions:', content: `We tailor our services to meet the unique needs of each client.` },
                    { span: 'Results-Oriented Focus:', content: `Our goal is to deliver measurable results and exceed client expectations.` },
                    { span: 'Collaboration and Partnership:', content: `We work closely with our clients to build strong partnerships and achieve shared objectives.` },

                ],
                contentType6: [
                    { span: 'Experienced Team:', content: `Our team consists of skilled professionals with a proven track record in digital marketing.` },
                    { span: 'Innovative Approach:', content: `We stay up-to-date with the latest trends and technologies to provide cutting-edge solutions.` },
                    { span: 'Client-Centric Focus: ', content: `We prioritize our clients' needs and strive to deliver exceptional service.` },
                    { span: 'Measurable Results:', content: `We provide transparent reporting and trackable metrics to demonstrate the success of our campaigns.` },

                ]

            });


        }
        else if (id === 'artificial-intelligence') {
            setPageName('Artificial Intelligence');
            setTitle('Artificial Intelligence (AI)');
            setImage(require('../assests/images/img-4.jpg'));
            setDetail1(`Artificial Intelligence (AI) is a broad field of computer science that deals with creating intelligent agents, which are systems that can reason, learn, and act autonomously. AI aims to simulate human intelligence in machines, enabling them to perform tasks that typically require human intelligence, such as understanding natural language, recognizing patterns, and solving complex problems.`);
            setTitle1(`Key Concepts in AI:`);
            setTitle2(`Applications of AI:`);
            setTitle3(`Ethical Considerations:`);
            setDetail2(`As AI continues to advance, it is important to consider the ethical implications of its development and use. Issues such as bias, privacy, and job displacement are among the key concerns that need to be addressed.`)

            setBlogData({
                contentType1: [
                    { span: 'Machine Learning:', content: `A subset of AI that focuses on algorithms that allow computers to learn from data and improve their performance over time without being explicitly programmed.  ` },
                    { span: 'Deep Learning:', content: `A type of machine learning that uses artificial neural networks with multiple layers to analyze and learn from complex patterns in data.  ` },
                    { span: 'Natural Language Processing (NLP): ', content: `The ability of computers to understand, interpret, and generate human language.` },
                    { span: 'Computer Vision:', content: `The ability of computers to interpret and understand visual information from the real world. ` },
                    { span: 'Robotics:', content: `The design and construction of robots that can perform tasks autonomously or with human guidance.` },


                ],
                contentType2: [
                    { content: `AI is being used in a wide range of industries and applications, including:` },
                    { span: 'Healthcare:', content: `Diagnosing diseases, drug discovery, personalized medicine` },
                    { span: 'Finance:', content: `Fraud detection, algorithmic trading, risk assessment` },
                    { span: 'Customer Service:', content: `Chatbots, virtual assistants` },
                    { span: 'Manufacturing:', content: `Automation, quality control` },
                    { span: 'Transportation:', content: `Self-driving cars, traffic management` },
                    { span: 'Entertainment:', content: `Game development, content recommendation` },
                ],
                contentType3: [
                    { span: 'Mobile Apps:', content: `Designed for smartphones and tablets, including native apps for iOS and Android.` },
                    { span: 'Web Applications:', content: `Accessed through a web browser, often using technologies like HTML, CSS, and JavaScript.` },
                    { span: 'Desktop Applications:', content: `Installed on a computer, providing standalone functionality.` },
                    { span: 'Enterprise Applications:', content: `Designed for large organizations to manage various business processes.` },
                ]

            });


        }
        else if (id === 'digital-marketing') {
            setPageName('Digital Marketing');
            setTitle('Digital Marketing');
            setImage(require('../assests/images/img-4.jpg'));
            setDetail1(`Alpha Infotech is a dynamic and innovative digital marketing agency dedicated to helping businesses thrive in the digital age. With a team of experienced professionals, we offer a comprehensive range of services designed to enhance online visibility, drive traffic, and generate leads. `);
            setTitle1(`Our Services:`);
            setTitle2(`Our Approach:`);
            setTitle3(`Why Choose Us:`);
            setBlogData({
                contentType1: [
                    { span: 'Search Engine Optimization (SEO):', content: `We optimize websites to rank higher in search engine results pages (SERPs), increasing organic traffic and visibility.` },
                    { span: 'Pay-Per-Click (PPC) Advertising:', content: ` We manage effective PPC campaigns on platforms like Google Ads and Bing Ads to target specific audiences and drive immediate results.` },
                    { span: 'Social Media Marketing:', content: `We create engaging social media content, build online communities, and manage social media campaigns to increase brand awareness and reach.` },
                    { span: 'Content Marketing:', content: `We develop high-quality content, such as blog posts, articles, and videos, to attract and engage your target audience.` },
                    { span: 'Email Marketing:', content: `We design and execute targeted email marketing campaigns to nurture leads, drive sales, and build customer loyalty.` },
                    { span: 'Website Design and Development:', content: `We create visually appealing and user-friendly websites that are optimized for both search engines and user experience.` },
                    { span: 'Analytics and Reporting:', content: `We track and analyze key performance indicators (KPIs) to measure the success of your digital marketing efforts and provide actionable insights.` },

                ],
                contentType2: [
                    { span: 'Data-Driven Strategies:', content: `We rely on data and analytics to inform our strategies and ensure maximum ROI.` },
                    { span: 'Customized Solutions:', content: `We tailor our services to meet the unique needs of each client.` },
                    { span: 'Results-Oriented Focus:', content: `Our goal is to deliver measurable results and exceed client expectations.` },
                    { span: 'Collaboration and Partnership:', content: `We work closely with our clients to build strong partnerships and achieve shared objectives.` },

                ],
                contentType3: [
                    { span: 'Experienced Team:', content: `Our team consists of skilled professionals with a proven track record in digital marketing.` },
                    { span: 'Innovative Approach:', content: `We stay up-to-date with the latest trends and technologies to provide cutting-edge solutions.` },
                    { span: 'Client-Centric Focus: ', content: `We prioritize our clients' needs and strive to deliver exceptional service.` },
                    { span: 'Measurable Results:', content: `We provide transparent reporting and trackable metrics to demonstrate the success of our campaigns.` },

                ]

            });


        }
        else if (id === 'graphic-designers') {
            setPageName('Graphic Designers');
            setTitle('Graphic Designers');
            setImage(require('../assests/images/img-4.jpg'));
            setDetail1(`In the fast-paced world of technology, your brand's visual identity is crucial. Our graphic design services for IT companies focus on creating stunning visuals that communicate innovation and expertise.`);
            setTitle1(`We specialize in:`);
            setTitle2(`Our Approach:`);
            setTitle3(`Why Choose Us:`);
            setDetail2(`With a deep understanding of the IT landscape, our designs are tailored to resonate with your target audience, enhancing your brand’s credibility and attracting new clients. Let us help you tell your story through compelling visuals that inspire and connect!`);
            setBlogData({
                contentType1: [
                    { content: `Brand Identity Development: Crafting logos, color schemes, and typography that reflect your company’s mission and values.` },
                    { content: `Marketing Collateral: Designing brochures, flyers, and presentations that effectively showcase your services and solutions.` },
                    { content: `Web and App Design: Creating user-friendly interfaces that enhance user experience and engagement, ensuring your digital presence is as dynamic as your technology.` },
                    { content: `Infographics: Transforming complex data into visually appealing graphics that make information easy to understand and share.` },
                    { content: `Social Media Graphics: Developing eye-catching posts and ads that drive engagement and enhance your online presence.` },
                    { content: `Custom Illustrations: Producing unique visuals that differentiate your brand in a crowded market.` },



                ],

            });


        }
        else {
            setPageName('Service Detail');
            setTitle('Service Detail');
            setImage(require('../assests/images/rv-4-banner-bg-1.jpg'));
            setDetail1(`55It appears that your query is still quite broad. If you're
                referring to information about an agency, I'd be happy to help if you provide more details
                or clarify the type of agency you're interested in. For example, are you looking for
                information about a creative agency, marketing agency, design agency, or another type of
                agency?`);
        }
    }, [id]);
    // accordionItems


    // Extract the 'id' parameter from the URL

    // Conditional rendering based on the 'id'
    // const renderDetail = () => {
    //     if (id === '1') {
    //         return <div><div class="container breadBox">
    //         <h1 class="rv-breadcrumb__title Aboutsec">{pageName}</h1>

    //         <ul class="rv-breadcrumb__nav d-flex justify-content-center">
    //             <li><Link to="/"><FontAwesomeIcon icon={faHouse} /> Home</Link></li>
    //             <li class="current-page"><span class="dvdr"> &#47;</span><span class="dvdr">SEO Optimization</span></li>
    //         </ul>
    //     </div></div>;
    //     } else if (id === '2') {
    //         return <div><div class="container breadBox">
    //         <h1 class="rv-breadcrumb__title Aboutsec">Content Marketing</h1>

    //         <ul class="rv-breadcrumb__nav d-flex justify-content-center">
    //             <li><Link to="/"><FontAwesomeIcon icon={faHouse} /> Home</Link></li>
    //             <li class="current-page"><span class="dvdr"> &#47;</span><span class="dvdr">Content Marketing</span></li>
    //         </ul>
    //     </div></div>;
    //     } else if (id === '3') {
    //         return <div><div class="container breadBox">
    //         <h1 class="rv-breadcrumb__title Aboutsec">Data Analysis</h1>

    //         <ul class="rv-breadcrumb__nav d-flex justify-content-center">
    //             <li><Link to="/"><FontAwesomeIcon icon={faHouse} /> Home</Link></li>
    //             <li class="current-page"><span class="dvdr"> &#47;</span><span class="dvdr">Data Analysis</span></li>
    //         </ul>
    //     </div></div>;
    //     }
    //     else if (id === '4') {
    //         return <div><div class="container breadBox">
    //         <h1 class="rv-breadcrumb__title Aboutsec">Digital Marketing</h1>

    //         <ul class="rv-breadcrumb__nav d-flex justify-content-center">
    //             <li><Link to="/"><FontAwesomeIcon icon={faHouse} /> Home</Link></li>
    //             <li class="current-page"><span class="dvdr"> &#47;</span><span class="dvdr">Digital Marketing</span></li>
    //         </ul>
    //     </div></div>;
    //     }
    //     else if (id === '5') {
    //         return <div><div class="container breadBox">
    //         <h1 class="rv-breadcrumb__title Aboutsec">Web Analytics</h1>

    //         <ul class="rv-breadcrumb__nav d-flex justify-content-center">
    //             <li><Link to="/"><FontAwesomeIcon icon={faHouse} /> Home</Link></li>
    //             <li class="current-page"><span class="dvdr"> &#47;</span><span class="dvdr">Web Analytics</span></li>
    //         </ul>
    //     </div></div>;
    //     } else {
    //         return <div></div>;
    //     }
    // };
    // Extract the 'id' parameter from the URL
    return (
        <div>
            {/* <!-- HEADER SECTION STARTS HERE --> */}

            <HeaderInner />

            {/* <!-- HEADER SECTION ENDS HERE --> */}


            {/* <!-- BREADCRUMB STARTS HERE --> */}

            <div class="rv-breadcrumb pt-120 pb-120 about-page">
                <div class="imageOverlay"></div>

                <div class="container breadBox">
                    <h1 class="rv-breadcrumb__title Aboutsec">{pageName}</h1>

                    <ul class="rv-breadcrumb__nav d-flex justify-content-center">
                        <li><Link to="/"><FontAwesomeIcon icon={faHouse} /> Home</Link></li>
                        <li class="current-page"><span class="dvdr"> &#47;</span><span class="dvdr">Services</span>
                            <span class="dvdr"> &#47;</span><span class="dvdr">{pageName}</span></li>
                    </ul>
                </div>
            </div>
            {/* <!-- BREADCRUMB ENDS HERE --> */}

            {/* <!-- SERVICE DETAILS SECTION START --> */}
            <section class="rv-service-details rv-section-spacing">
                <div class="container">
                    <div class="row rv-service-details__row g-30 justify-content-center">
                        <div class="col-lg-8">
                            <div class="rv-service-details__left">
                                <div class="rv-service-details__img">
                                    <img src={image} alt="Service Details Cover" />
                                </div>
                                <h3 class="rv-service-details__title">{title}</h3>
                                <p class="rv-service-details__descr">{detail1}</p>

                                <h4>{title1}</h4>
                                <ul class="blog_detail">
                                    {blogData.contentType1?.map((item, index) => (
                                        <li key={index}>
                                            <span>{item.span}</span>{item.content}
                                        </li>
                                    ))}

                                </ul>
                                <h4>{title2}</h4>
                                <ul class="blog_detail">
                                    {blogData.contentType2?.map((item, index) => (
                                        <li key={index}>
                                            <span>{item.span}</span>{item.content}
                                        </li>
                                    ))}


                                </ul>
                                <h4>{title3}</h4>
                                <ul class="blog_detail">
                                    {blogData.contentType3?.map((item, index) => (
                                        <li key={index}>
                                            <span>{item.span}</span>{item.content}
                                        </li>
                                    ))}

                                </ul>


                                {/* ---------- */}
                                <p class="rv-service-details__descr mt-3">{detail2}</p>
                                <h4>{title4}</h4>
                                <ul class="blog_detail">
                                    {blogData.contentType4?.map((item, index) => (
                                        <li key={index}>
                                            <span>{item.span}</span>{item.content}
                                        </li>
                                    ))}

                                </ul>
                                <h4>{title5}</h4>
                                <ul class="blog_detail">
                                    {blogData.contentType5?.map((item, index) => (
                                        <li key={index}>
                                            <span>{item.span}</span>{item.content}
                                        </li>
                                    ))}


                                </ul>
                                <h4>{title6}</h4>
                                <ul class="blog_detail">
                                    {blogData.contentType6?.map((item, index) => (
                                        <li key={index}>
                                            <span>{item.span}</span>{item.content}
                                        </li>
                                    ))}

                                </ul>



                                {/* <blockquote class="rv-blog-details-descr__txt rv-service-details__blockquote blockquote">
                                    <p>
                                        Lorem ipsum dolor sit abet connecters dehiscing alit sed aliquot ex
                                        supine maximus sit ramet mi laurate untraces.
                                    </p>

                                    <h6 class="blockquote__author-name">Norman Gordon</h6>
                                </blockquote> */}
                                {/* <div class="rv-service-details__cards">
                                    <div class="row g-30">
                                        <div class="col-sm-6">
                                            <div class="rv-service-details-card">
                                                <span class="rv-service-details-card__tag">Creative Design</span>
                                                <div class="rv-service-details-card__icon">
                                                    <img src={require('../assests/images/rv-service-details-icon-1.png')} alt="Icon" />
                                                </div>
                                                <p class="rv-service-details-card__descr">Please Provide More Specific Details <span class="highlight"> Creative Design</span> Questions Related To.
                                                </p>
                                                <a href="#" class=" rv-service-details-card__btn rv-3-def-btn">Read More</a>
                                            </div>
                                        </div>

                                        <div class="col-sm-6">
                                            <div class="rv-service-details-card">
                                                <span class="rv-service-details-card__tag">Marketing Plan</span>
                                                <div class="rv-service-details-card__icon">
                                                    <img src={require('../assests/images/rv-service-details-icon-2.png')} alt="Icon" />
                                                </div>
                                                <p class="rv-service-details-card__descr">Create a Timeline Implementing Your
                                                    For <span class="highlight">Marketing</span> Strategies and Tactics.</p>
                                                <a href="#" class="rv-3-def-btn rv-service-details-card__btn">Read More</a>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}

                                {/* <!-- accordion --> */}
                                {/* <h3 class="rv-service-details__title">Frequently Asked Questions</h3>
                                <div class="rv-accordion">
                                    <div class="rv-accordion-item open">
                                        <div class="rv-accordion-item-header">
                                            <h6 class="rv-accordion-item-title">How many hours of lessons do you need to pass?
                                            </h6>
                                            <span class="rv-accordion-item-expand-icon"></span>
                                        </div>

                                        <p class="rv-accordion-item-body">
                                            Sed interdum ipsum fermentum, condimentum erat eget, egestas est. Etiam vel justo
                                            laoreet, efficitur lectus in, tincidunt magna. Aliquam eros lorem, rhoncus quis
                                            justo id.
                                        </p>
                                    </div>

                                    <div class="rv-accordion-item">
                                        <div class="rv-accordion-item-header">
                                            <h6 class="rv-accordion-item-title">How to Handle Tailgaters on the Road?</h6>
                                            <span class="rv-accordion-item-expand-icon"></span>
                                        </div>

                                        <p class="rv-accordion-item-body">
                                            Sed interdum ipsum fermentum, condimentum erat eget, egestas est. Etiam vel justo
                                            laoreet, efficitur lectus in, tincidunt magna. Aliquam eros lorem, rhoncus quis
                                            justo id.
                                        </p>
                                    </div>

                                    <div class="rv-accordion-item">
                                        <div class="rv-accordion-item-header">
                                            <h6 class="rv-accordion-item-title">How many driving lessons do I need before test?
                                            </h6>
                                            <span class="rv-accordion-item-expand-icon"></span>
                                        </div>

                                        <p class="rv-accordion-item-body">
                                            Sed interdum ipsum fermentum, condimentum erat eget, egestas est. Etiam vel justo
                                            laoreet, efficitur lectus in, tincidunt magna. Aliquam eros lorem, rhoncus quis
                                            justo id.
                                        </p>
                                    </div>
                                </div> */}
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 col-9 col-xxs-12">
                            {/* <div class="rv-service-details-sidebar-widget">
                                <h5 class="rv-service-details-sidebar-widget__title">Search</h5>
                                <form action="#" class="rv-service-details-sidebar__search-form">
                                    <input type="search" name="search" placeholder="Search here..." />
                                    <button type="submit"><FontAwesomeIcon icon={faMagnifyingGlass} /></button>
                                </form>
                            </div> */}

                            <div class="rv-service-details-sidebar-widget rv-service-details-sidebar-categories">
                                <ul>
                                    <li><Link 
                                     to="#"
                                     onClick={() => {window.location.href = '/services/website-development'; }}>Website Development</Link></li>
                                     <li><Link 
                                     to="#"
                                     onClick={() => {window.location.href = '/services/business-planning'; }}>Business Planning</Link></li>
                                     <li><Link 
                                     to="#"
                                     onClick={() => {window.location.href = '/services/application-development'; }}>Application Development</Link></li>
                                     <li><Link 
                                     to="#"
                                     onClick={() => {window.location.href = '/services/artificial-intelligence'; }}>Artificial Intelligence</Link></li>
                                     <li><Link 
                                     to="#"
                                     onClick={() => {window.location.href = '/services/digital-marketing'; }}>Digital Marketing</Link></li>
                                    {/* <li><Link to="/services/business-planning">Business Planning</Link></li>
                                    <li><Link to="/services/application-development">Application Development</Link></li>
                                    <li><Link to="/services/artificial-intelligence">Artificial Intelligence</Link></li> */}
                                    {/* <li><a href="#">SEO & Content Writing</a></li> */}
                                    {/* <li><Link to="/services/digital-marketing">Digital Marketing</Link></li> */}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- SERVICE DETAILS SECTION END --> */}


            {/* <!-- FOOTER SECTION START --> */}
            <FooterInner />
            {/* <!-- FOOTER SECTION END --> */}

        </div>
    )
}

export default ServiceD