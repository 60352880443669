
import React from 'react'
import "../App.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { faHouse } from "@fortawesome/free-solid-svg-icons"
import { Link } from 'react-router-dom';
import {faStar} from "@fortawesome/free-solid-svg-icons";
import {faChevronUp} from "@fortawesome/free-solid-svg-icons"
import {faChevronDown} from "@fortawesome/free-solid-svg-icons"
import {faHeart} from "@fortawesome/free-regular-svg-icons";
import {faPlay} from "@fortawesome/free-solid-svg-icons";
import{faBagShopping} from "@fortawesome/free-solid-svg-icons";
import HeaderInner from '../component/HeaderInner';
import FooterInner from '../component/FooterInner';
import { useEffect ,useState } from "react";

const ProductD = () => {
    
    
  return (
    <div>
         {/* <!-- HEADER SECTION STARTS HERE --> */}

        <HeaderInner/>

            {/* <!-- HEADER SECTION ENDS HERE --> */}


            {/* <!-- BREADCRUMB STARTS HERE --> */}
    <div class="rv-breadcrumb pt-120 pb-120">
        <div class="container">
            <h1 class="rv-breadcrumb__title">Portfolio Details</h1>

            <ul class="rv-breadcrumb__nav d-flex justify-content-center">
            <li><Link to="/"><FontAwesomeIcon icon={faHouse} /> Home</Link></li>
                <li class="current-page"><span class="dvdr"> &#47;</span><span>Portfolio Details</span></li>
            </ul>
        </div>
    </div>
    {/* <!-- BREADCRUMB ENDS HERE --> */}



    {/* <!-- PRODUCT DETAILS SECTION STARTS HERE --> */}
    <section class="rv-product-details rv-section-spacing">
        <div class="container">
            <div class="row g-0 align-items-start justify-content-center">
                <div class="col-lg-6 col-12 col-xxs-12">
                    <div class="rv-product-details__imgs">
                        <div class="rv-product-details-img-slider-1 swiper" id="rv-product-details-img-slider-1">
                            <div class="swiper-wrapper">
                                <div class="swiper-slide">
                                    <div class="rv-product-details__thumb"> <img
                                            src={require('../assests/images/rv-product-details-1.png')} alt="Product Image"/></div>
                                </div>
                                <div class="swiper-slide">
                                    <div class="rv-product-details__thumb"> <img
                                            src={require('../assests/images/rv-product-details-2.png')} alt="Product Image"/></div>
                                </div>
                                <div class="swiper-slide">
                                    <div class="rv-product-details__thumb"> <img
                                            src={require('../assests/images/rv-product-details-3.png')} alt="Product Image"/></div>
                                </div>
                                <div class="swiper-slide">
                                    <div class="rv-product-details__thumb"> <img
                                            src={require('../assests/images/rv-product-details-4.png')} alt="Product Image"/></div>
                                </div>
                            </div>
                        </div>

                        <div class="rv-product-details-img-slider-2 swiper" id="rv-product-details-img-slider-2">
                            <div class="swiper-wrapper">
                                <div class="rv-product-details__img swiper-slide"><img
                                        src={require('../assests/images/rv-product-details-1.png')} alt="Product Image"/></div>
                                <div class="rv-product-details__img swiper-slide"><img
                                        src={require('../assests/images/rv-product-details-2.png')} alt="Product Image"/></div>
                                <div class="rv-product-details__img swiper-slide"><img
                                        src={require('../assests/images/rv-product-details-3.png')} alt="Product Image"/></div>
                                <div class="rv-product-details__img swiper-slide"><img
                                        src={require('../assests/images/rv-product-details-4.png')} alt="Product Image"/></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6">
                    <div class="rv-product-details__top-txt">
                        <h2 class="rv-product-details__title">Satin Single Long</h2>

                        <div class="rv-product-details__rating">
                            <div class="rating">
                                <FontAwesomeIcon icon={faStar} />
                                <FontAwesomeIcon icon={faStar} />
                                <FontAwesomeIcon icon={faStar} />
                                <FontAwesomeIcon icon={faStar} />
                                <FontAwesomeIcon icon={faStar} />
                            </div>
                            <h6 class="mb-0">(3 Customer Reviews)</h6>
                        </div>

                        <p class="rv-product-details__short-descr">Morbi molestie a feugiat sed mauris eu metus
                            vestibulum varius Phasellus nisl mauris. Suspendisse tristique, neque blandit egestas, risus
                            orci lacinia ante, sit amet pretium enim.</p>

                        <h4 class="rv-product-details__price">
                            <span class="prev-price">$199.00</span>
                            <span class="current-price">$139.00</span>
                        </h4>

                        <div class="rv-product-details__actions">
                            <div class="rv-product-details__quantity cart-product__quantity">
                                <input type="number" name="product-quantity" id="product-quantity-input"
                                    class="cart-product-quantity-input" value="01"/>
                                <div>
                                    <button class="minus-btn cart-product__minus"><FontAwesomeIcon icon={faChevronUp} /></button>
                                    <button class="plus-btn cart-product__plus"><FontAwesomeIcon icon={faChevronDown} /></button>
                                </div>
                            </div>

                            <button class="rv-product-details__add-to-wishlist"><FontAwesomeIcon icon={faHeart} /></button>
                            <button class="rv-product-details__add-to-cart">Add to cart</button>
                        </div>

                        <div class="rv-product-details__infos">
                            <ul>
                                <li><span class="info-property"> Brand:</span> <span class="info-value">Wet n
                                        wild</span></li>
                                <li><span class="info-property"> Scent: </span> <span
                                        class="info-value">Fragranced</span></li>
                                <li><span class="info-property"> Item Form: </span> <span
                                        class="info-value">Cream</span></li>
                                <li><span class="info-property"> Active Ingredients: </span> <span
                                        class="info-value">Not Tested On Animals</span></li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="col-12">
                    <div class="rv-product-details__bottom-txt">
                        <ul class="nav nav-tabs" id="myTab" role="tablist">
                            <li class="nav-item" role="presentation">
                                <button class="nav-link active" id="descr-tab" data-bs-toggle="tab"
                                    data-bs-target="#descr-tab-pane" type="button" role="tab"
                                    aria-controls="descr-tab-pane" aria-selected="true">Description</button>
                            </li>
                            <li class="nav-item" role="presentation">
                                <button class="nav-link" id="review-tab" data-bs-toggle="tab"
                                    data-bs-target="#review-tab-pane" type="button" role="tab"
                                    aria-controls="review-tab-pane" aria-selected="false">Reviews</button>
                            </li>
                        </ul>
                        <div class="tab-content" id="myTabContent">
                            <div class="tab-pane fade show active" id="descr-tab-pane" role="tabpanel"
                                aria-labelledby="descr-tab" tabindex="0">
                                <div class="rv-product-details__descr">
                                    <h6 class="rv-product-details-bottom__title">Product Description</h6>
                                    <p>Make those striking eyes of yours pop with our improved Color Icon eyeshadow
                                        formula. We’ve combined super-saturated pigmentation and pearlescents into our
                                        go-to singles. The satiny texture feels as good as it looks–sans fading or
                                        creasing–with better blendability and major staying power. Reformulated matte
                                        shadows softly glide over skin Holds fast to the eyelids despite the rubs of
                                        everyday life Provides intense color payout with high-impact pigments Delivers
                                        an elegant silky texture and superior blendability Applies smoothly and evenly
                                        with silica-enriched formula</p>
                                </div>
                            </div>


                            <div class="tab-pane fade" id="review-tab-pane" role="tabpanel" aria-labelledby="review-tab"
                                tabindex="0">
                                <div class="fz-product-details__review">
                                    <div class="review-overview">
                                        <div class="average-rating-area">
                                            <h3><span>4.3</span><span>/5</span></h3>
                                            <span class="rating-amount">24 ratings</span>
                                        </div>

                                        <div class="review-breakdown">
                                            <ul class="individual-star-breakdown">
                                                <li class="star">
                                                    <i class="fa-solid fa-sharp fa-star"></i>
                                                    <i class="fa-solid fa-sharp fa-star"></i>
                                                    <i class="fa-solid fa-sharp fa-star"></i>
                                                    <i class="fa-solid fa-sharp fa-star"></i>
                                                    <i class="fa-solid fa-sharp fa-star"></i>
                                                </li>
                                                <li>
                                                    <div class="bar">
                                                        <div class="filled"></div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div class="each-star-amount">320</div>
                                                </li>
                                            </ul>

                                            <ul class="individual-star-breakdown individual-star-breakdown-2">
                                                <li class="star">
                                                    <i class="fa-solid fa-sharp fa-star"></i>
                                                    <i class="fa-solid fa-sharp fa-star"></i>
                                                    <i class="fa-solid fa-sharp fa-star"></i>
                                                    <i class="fa-solid fa-sharp fa-star"></i>
                                                    <i class="fa-light fa-star"></i>
                                                </li>
                                                <li>
                                                    <div class="bar">
                                                        <div class="filled"></div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div class="each-star-amount">250</div>
                                                </li>
                                            </ul>

                                            <ul class="individual-star-breakdown individual-star-breakdown-3">
                                                <li class="star">
                                                    <i class="fa-solid fa-sharp fa-star"></i>
                                                    <i class="fa-solid fa-sharp fa-star"></i>
                                                    <i class="fa-solid fa-sharp fa-star"></i>
                                                    <i class="fa-light fa-star"></i>
                                                    <i class="fa-light fa-star"></i>
                                                </li>
                                                <li>
                                                    <div class="bar">
                                                        <div class="filled"></div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div class="each-star-amount">140</div>
                                                </li>
                                            </ul>

                                            <ul class="individual-star-breakdown individual-star-breakdown-4">
                                                <li class="star">
                                                    <i class="fa-solid fa-sharp fa-star"></i>
                                                    <i class="fa-solid fa-sharp fa-star"></i>
                                                    <i class="fa-light fa-star"></i>
                                                    <i class="fa-light fa-star"></i>
                                                    <i class="fa-light fa-star"></i>
                                                </li>
                                                <li>
                                                    <div class="bar">
                                                        <div class="filled"></div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div class="each-star-amount">83</div>
                                                </li>
                                            </ul>

                                            <ul class="individual-star-breakdown individual-star-breakdown-5">
                                                <li class="star">
                                                    <i class="fa-solid fa-sharp fa-star"></i>
                                                    <i class="fa-light fa-star"></i>
                                                    <i class="fa-light fa-star"></i>
                                                    <i class="fa-light fa-star"></i>
                                                    <i class="fa-light fa-star"></i>
                                                </li>
                                                <li>
                                                    <div class="bar">
                                                        <div class="filled"></div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div class="each-star-amount">11</div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>


                                    <div class="user-reviews">
                                        <h4 class="rv-product-details-bottom__title">Reviews of this product</h4>
                                        <div class="row g-4 row-cols-md-2 row-cols-1">
                                            <div class="col">
                                                <div class="rv-product-details__review">
                                                    <div class="user">
                                                        <div class="user-img">
                                                            <img src={require('../assests/images/rv-3-member-1.jpg')} alt="user"/>
                                                        </div>
                                                        <div class="user-info">
                                                            <h6 class="user-name">Eliza nolan</h6>
                                                            <div class="user-rating">
                                                                <i class="fa-solid fa-sharp fa-star"></i>
                                                                <i class="fa-solid fa-sharp fa-star"></i>
                                                                <i class="fa-solid fa-sharp fa-star"></i>
                                                                <i class="fa-solid fa-sharp fa-star"></i>
                                                                <i class="fa-light fa-sharp fa-star"></i>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="review">
                                                        <p>
                                                            Lorem ipsum dolor sit amet consectetur adipisicing elit.
                                                            Suscipit voluptatum quaerat nemo eaque delectus ratione
                                                            maiores expedita pariatur illum facilis at repellendus
                                                            nesciunt veniam animi, omnis corrupti reiciendis explicabo
                                                            itaque id. Maxime consequatur recusandae fugiat accusamus
                                                            ipsam reiciendis, officiis esse assumenda voluptas
                                                            aspernatur consequuntur? Eaque sed quibusdam ipsum saepe
                                                            nulla!
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col">
                                                <div class="rv-product-details__review">
                                                    <div class="user">
                                                        <div class="user-img">
                                                            <img src={require('../assests/images/rv-3-member-2.jpg')}alt="user"/>
                                                        </div>
                                                        <div class="user-info">
                                                            <h6 class="user-name">Abu Amer</h6>
                                                            <div class="user-rating">
                                                                <i class="fa-solid fa-sharp fa-star"></i>
                                                                <i class="fa-solid fa-sharp fa-star"></i>
                                                                <i class="fa-solid fa-sharp fa-star"></i>
                                                                <i class="fa-light fa-sharp fa-star"></i>
                                                                <i class="fa-light fa-sharp fa-star"></i>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="review">
                                                        <p>
                                                            Lorem ipsum dolor sit amet consectetur adipisicing elit.
                                                            Suscipit voluptatum quaerat nemo eaque delectus ratione
                                                            maiores expedita pariatur illum facilis at repellendus
                                                            nesciunt veniam animi, omnis corrupti reiciendis explicabo
                                                            itaque id. Maxime consequatur recusandae fugiat accusamus
                                                            ipsam reiciendis, officiis esse assumenda voluptas
                                                            aspernatur consequuntur? Eaque sed quibusdam ipsum saepe
                                                            nulla!
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col">
                                                <div class="rv-product-details__review">
                                                    <div class="user">
                                                        <div class="user-img">
                                                            <img src={require('../assests/images/rv-3-member-3.jpg')} alt="user"/>
                                                        </div>
                                                        <div class="user-info">
                                                            <h6 class="user-name">Brunt glenn</h6>
                                                            <div class="user-rating">
                                                                <i class="fa-solid fa-sharp fa-star"></i>
                                                                <i class="fa-solid fa-sharp fa-star"></i>
                                                                <i class="fa-solid fa-sharp fa-star"></i>
                                                                <i class="fa-solid fa-sharp fa-star"></i>
                                                                <i class="fa-solid fa-sharp fa-star"></i>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="review">
                                                        <p>
                                                            Lorem ipsum dolor sit amet consectetur adipisicing elit.
                                                            Suscipit voluptatum quaerat nemo eaque delectus ratione
                                                            maiores expedita pariatur illum facilis at repellendus
                                                            nesciunt veniam animi, omnis corrupti reiciendis explicabo
                                                            itaque id. Maxime consequatur recusandae fugiat accusamus
                                                            ipsam reiciendis, officiis esse assumenda voluptas
                                                            aspernatur consequuntur? Eaque sed quibusdam ipsum saepe
                                                            nulla!
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col">
                                                <div class="rv-product-details__review">
                                                    <div class="user">
                                                        <div class="user-img">
                                                            <img src={require('../assests/images/rv-5-member-1.jpg')} alt="user"/>
                                                        </div>
                                                        <div class="user-info">
                                                            <h6 class="user-name">chad hossain</h6>
                                                            <div class="user-rating">
                                                                <i class="fa-solid fa-sharp fa-star"></i>
                                                                <i class="fa-solid fa-sharp fa-star"></i>
                                                                <i class="fa-solid fa-sharp fa-star"></i>
                                                                <i class="fa-solid fa-sharp fa-star"></i>
                                                                <i class="fa-light fa-sharp fa-star"></i>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="review">
                                                        <p>
                                                            Lorem ipsum dolor sit amet consectetur adipisicing elit.
                                                            Suscipit voluptatum quaerat nemo eaque delectus ratione
                                                            maiores expedita pariatur illum facilis at repellendus
                                                            nesciunt veniam animi, omnis corrupti reiciendis explicabo
                                                            itaque id. Maxime consequatur recusandae fugiat accusamus
                                                            ipsam reiciendis, officiis esse assumenda voluptas
                                                            aspernatur consequuntur? Eaque sed quibusdam ipsum saepe
                                                            nulla!
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    {/* <!-- PRODUCT DETAILS SECTION ENDS HERE --> */}





    {/* <!-- VIDEO SECTION START --> */}
    <div class="rv-inner-video rv-10-infos__video">
        <div class="rv-5-video">
            <div class="container">
                <div class="outer-container">
                    <span class="btn-wrapper">
                        <a href="https://youtu.be/PWbSLgZWBhU?si=fgEd0VhEzqiiSmIN" data-fslightbox>
                        <FontAwesomeIcon icon={faPlay} />
                        </a>
                    </span>
                    <h3>Health Care & Beauty.</h3>
                </div>
            </div>
        </div>
    </div>
    {/* <!-- VIDEO SECTION END --> */}


    {/* <!-- RELATED PRODUCT SECTION STARTS HERE --> */}
    <section class="rv-related-prod rv-section-spacing">
        <div class="container">
            <div class="rv-3-section-heading rv-related-prod-heading">
                <div class="rv-3-section-heading__left">
                    <h6 class="rv-7-section__sub-title">New Collection</h6>
                    <h2 class="rv-related-prod__title">Featured Products</h2>
                </div>

                <div class="rv-3-section-heading__right">
                    <a href="#" class="rv-3-def-btn rv-12-banner__btn">Shop all Collections</a>
                </div>
            </div>

            <div class="row rv-related-prod-row g-30 justify-content-center">
                <div class="col-xl-3 col-md-4 col-6 col-xxs-12">
                    <div class="rv-7-product rv-inner-product">
                        <div class="rv-3-product__img rv-7-product__img">
                            <img src={require('../assests/images/rv-inner-product-1.png')} alt="Product Image"/>
                        </div>

                        <div class="rv-inner-product__hover-img">
                            <a href="#">
                                <img src={require('../assests/images/rv-inner-pro-hover-img-2.jpg')} alt="Hover image"/>
                            </a>
                        </div>

                        <div class="rv-3-product__actions">
                            <button class="add-to-wishlist"><FontAwesomeIcon icon={faHeart} /></button>
                        </div>

                        <div class="rv-7-product__txt">
                            <div class="rv-3-product__rating">
                                <i class="fa-solid fa-star"></i>
                                <i class="fa-solid fa-star"></i>
                                <i class="fa-solid fa-star"></i>
                                <i class="fa-solid fa-star"></i>
                                <i class="fa-regular fa-star"></i>
                            </div>
                            <h5 class="rv-3-product__title"><a href="#">Waterpik Ultra Water</a></h5>
                            <span class="rv-3-product__price">
                                <span class="prev-price">$85.00</span>
                                <span class="current-price">$74.00</span>
                            </span>

                            <div class="rv-3-product__bottom">
                                <button class="rv-3-product__cart-btn"><FontAwesomeIcon icon={faBagShopping} /> Add To
                                    Cart</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-xl-3 col-md-4 col-6 col-xxs-12">
                    <div class="rv-7-product rv-inner-product">
                        <div class="rv-3-product__img rv-7-product__img">
                            <img src={require('../assests/images/rv-inner-product-2.png')} alt="Product Image"/>
                        </div>

                        <div class="rv-inner-product__hover-img">
                            <a href="#">
                                <img src={require('../assests/images/rv-inner-pro-hover-img-2.jpg')} alt="Hover image"/>
                            </a>
                        </div>

                        <div class="rv-3-product__actions">
                            <button class="add-to-wishlist"><FontAwesomeIcon icon={faHeart} /></button>
                        </div>

                        <div class="rv-7-product__txt">
                            <div class="rv-3-product__rating">
                                <i class="fa-solid fa-star"></i>
                                <i class="fa-solid fa-star"></i>
                                <i class="fa-solid fa-star"></i>
                                <i class="fa-solid fa-star"></i>
                                <i class="fa-regular fa-star"></i>
                            </div>
                            <h5 class="rv-3-product__title"><a href="#">Waterpik Ultra Water</a></h5>
                            <span class="rv-3-product__price">
                                <span class="prev-price">$85.00</span>
                                <span class="current-price">$74.00</span>
                            </span>

                            <div class="rv-3-product__bottom">
                                <button class="rv-3-product__cart-btn"><FontAwesomeIcon icon={faBagShopping} /> Add To
                                    Cart</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-xl-3 col-md-4 col-6 col-xxs-12">
                    <div class="rv-7-product rv-inner-product">
                        <div class="rv-3-product__img rv-7-product__img">
                            <img src={require('../assests/images/rv-inner-product-3.png')} alt="Product Image"/>
                        </div>

                        <div class="rv-inner-product__hover-img">
                            <a href="#">
                                <img src={require('../assests/images/rv-inner-pro-hover-img-2.jpg')} alt="Hover image"/>
                            </a>
                        </div>

                        <div class="rv-3-product__actions">
                            <button class="add-to-wishlist"><FontAwesomeIcon icon={faHeart} /></button>
                        </div>

                        <div class="rv-7-product__txt">
                            <div class="rv-3-product__rating">
                                <i class="fa-solid fa-star"></i>
                                <i class="fa-solid fa-star"></i>
                                <i class="fa-solid fa-star"></i>
                                <i class="fa-solid fa-star"></i>
                                <i class="fa-regular fa-star"></i>
                            </div>
                            <h5 class="rv-3-product__title"><a href="#">Waterpik Ultra Water</a></h5>
                            <span class="rv-3-product__price">
                                <span class="prev-price">$85.00</span>
                                <span class="current-price">$74.00</span>
                            </span>

                            <div class="rv-3-product__bottom">
                                <button class="rv-3-product__cart-btn"><FontAwesomeIcon icon={faBagShopping} /> Add To
                                    Cart</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-xl-3 col-md-4 col-6 col-xxs-12">
                    <div class="rv-7-product rv-inner-product">
                        <div class="rv-3-product__img rv-7-product__img">
                            <img src={require('../assests/images/rv-inner-product-4.png')} alt="Product Image"/>
                        </div>

                        <div class="rv-inner-product__hover-img">
                            <a href="#">
                                <img src={require('../assests/images/rv-inner-pro-hover-img-2.jpg')} alt="Hover image"/>
                            </a>
                        </div>

                        <div class="rv-3-product__actions">
                            <button class="add-to-wishlist"><FontAwesomeIcon icon={faHeart} /></button>
                        </div>

                        <div class="rv-7-product__txt">
                            <div class="rv-3-product__rating">
                                <i class="fa-solid fa-star"></i>
                                <i class="fa-solid fa-star"></i>
                                <i class="fa-solid fa-star"></i>
                                <i class="fa-solid fa-star"></i>
                                <i class="fa-regular fa-star"></i>
                            </div>
                            <h5 class="rv-3-product__title"><a href="#">Waterpik Ultra Water</a></h5>
                            <span class="rv-3-product__price">
                                <span class="prev-price">$85.00</span>
                                <span class="current-price">$74.00</span>
                            </span>

                            <div class="rv-3-product__bottom">
                                <button class="rv-3-product__cart-btn"><FontAwesomeIcon icon={faBagShopping} /> Add To
                                    Cart</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    {/* <!-- RELATED PRODUCT SECTION ENDS HERE --> */}






    {/* <!-- GALLERY SECTION START --> */}
    <section class="rv-9-gallery rv-13-gallery rv-inner-gallery">
        <div class="row justify-content-center row-cols-lg-5 row-cols-sm-3 row-cols-2 rv-inner-gallery-row">
            <div class="col">
                <div class="rv-13-gallery__img">
                    <img src={require('../assests/images/rv-inner-gallery-1.jpg')} alt="Gallery-1"/>
                </div>
            </div>
            <div class="col">
                <div class="rv-13-gallery__img">
                    <img src={require('../assests/images/rv-inner-gallery-2.jpg')} alt="Gallery-1"/>
                </div>
            </div>
            <div class="col">
                <div class="rv-13-gallery__img">
                    <img src={require('../assests/images/rv-inner-gallery-3.jpg')} alt="Gallery-1"/>
                </div>
            </div>
            <div class="col">
                <div class="rv-13-gallery__img">
                    <img src={require('../assests/images/rv-inner-gallery-4.jpg')} alt="Gallery-1"/>
                </div>
            </div>
            <div class="col">
                <div class="rv-13-gallery__img">
                    <img src={require('../assests/images/rv-inner-gallery-5.jpg')} alt="Gallery-1"/>
                </div>
            </div>
        </div>

        <div class="rv-9-gallery__sticker rv-13-gallery__sticker">
            <h4 class="title">Instagram</h4>
            <h6 class="sub-title">Follow Us on</h6>
        </div>
    </section>
    {/* <!-- GALLERY SECTION END --> */}


{/* <!-- FOOTER SECTION START --> */}
<FooterInner/>
            {/* <!-- FOOTER SECTION END --> */}




    </div>
  )
}

export default ProductD