export const InnerData = {
    recent_project: [
        {
            id: 0,
            nameID: "tax-filling-application",
            heading: "Tax Filling Application",
            images: (require('../assests/images/Software/Portfolio/rv-34-portfolio-img1.jpg')),
            portfolioImage: (require('../assests/images/Software/Portfolio/rv-34-portfolio-img1.jpg')),
            title: "Application",

        },
        {
            id: 1,
            nameID: "management-application",
            heading: "Management Application",
            images: (require('../assests/images/Software/Portfolio/rv-34-portfolio-img2.jpg')),
            portfolioImage: (require('../assests/images/Software/Portfolio/rv-34-portfolio-img2.jpg')),
            title: "Application",

        },
        {
            id: 2,
            nameID: "babyCare-application",
            heading: "BabyCare Application",
            images: (require('../assests/images/Software/Portfolio/rv-34-portfolio-img3.jpg')),
            portfolioImage: (require('../assests/images/Software/Portfolio/rv-34-portfolio-img3.jpg')),
            title: "Application",

        },
        {
            id: 3,
            nameID: "student-tutor-application",
            heading: "Student Tutor Application",
            images: (require('../assests/images/Software/Portfolio/rv-34-portfolio-img4.jpg')),
            portfolioImage: (require('../assests/images/Software/Portfolio/rv-34-portfolio-img4.jpg')),
            title: "Application",

        },
        {
            id: 4,
            nameID: "ancla-application",
            heading: "Ancla Application",
            images: (require('../assests/images/Software/Portfolio/rv-34-portfolio-img5.jpg')),
            portfolioImage: (require('../assests/images/Software/Portfolio/rv-34-portfolio-img6.jpg')),
            title: "Application",

        },
        {
            id: 5,
            nameID: "merchant",
            heading: "Merchant",
            images: (require('../assests/images/Software/Portfolio/rv-34-portfolio-img6.jpg')),
            portfolioImage: (require('../assests/images/Software/Portfolio/rv-34-portfolio-img6.jpg')),
            title: "Application",

        },
        {
            id: 6,
            nameID: "fashion-network-application",
            heading: "Fashion network Application",
            images: (require('../assests/images/Software/Portfolio/rv-34-portfolio-img7.jpg')),
            portfolioImage: (require('../assests/images/Software/Portfolio/rv-34-portfolio-img7.jpg')),
            title: "Application",

        },

    ],
    testimonial: [
        {
            id: 0,
            name: "Vishal R",
            subtitle: "MArketing Head",
            comments: "“He has delivered satisfactory work exactly according to my requirements. He has good communication skills and understood the client requirements well.”",

        },
        {
            id: 0,
            name: "Anvesh k",
            subtitle: "Technical Lead",
            comments: "“I Had an issue with the existing code and I needed quick fix on that, I had no time to wait. I was searching for a person who can solve the issue quickly and efficiently. Thanks for delivering this in time. Greatly value the service. Will look forward to work with you again. ”",

        },
        {
            id: 0,
            name: "Dylan Meringue",
            subtitle: "Happy Clients",
            comments: "“The Kunal team is fabulous! Communication was great and time on task was efficiently managed. I would recommend them to anyone who is looking to take their development ideas to the next level.”",

        },
        {
            id: 0,
            name: "Sumit k",
            subtitle: "Director",
            comments: "“Expert with excellent communication, requirements gathering, analysis, management and development skills. Excellent skills in : Web and applications development. Highly recommended!”",

        },
        {
            id: 0,
            name: "New trend",
            subtitle: "Head of business",
            comments: "“Exceptional work by the kunal.his attention to detail, timely delivery, and effective communication made the entire project a seamless experience. The quality of the final product exceeded expectations, demonstrating a high level of expertise. I highly recommend their services and look forward to collaborating on future projects.”",

        },
        {
            id: 0,
            name: "Abdulaziz A.",
            subtitle: "CEO Code Base",
            comments: " “Great service and experience. of course will rehire in future. Thank you Kunal”",

        },
        {
            id: 0,
            name: "Ray Gilbride",
            subtitle: "President",
            comments: " “The Kunal team is fabulous! Communication was great and time on task was efficiently managed. I would recommend them to anyone who is looking to take their development ideas to the next level.”",

        },
        {
            id: 0,
            name: "Pakhi G",
            subtitle: "Designing Head ",
            comments: "“Doesn't rush the work 🙂 and tries to provide you with best quality  ”",

        },
        {
            id: 0,
            name: "Md Mehedi H",
            subtitle: "Manage Partner",
            comments: "“Good person and Honestly. Doing the Good Job. Thank you”",

        },
        {
            id: 0,
            name: "Pakhi G",
            subtitle: "Designing Head ",
            comments: "“Doesn't rush the work 🙂 and tries to provide you with best quality  ”",

        },

    ],
    team_member:
        [
            {
                id: 0,
                name: "Ravi O'Leigh",
                title: "CHEF TECHNOLOGY",
                image: (require('../assests/images/Software/team/rv-34-team1.jpg')),
            },
        ],

    blog: [
        {
            id: 0,
            nameID:"medical-consultancy-application",
            heading: "Medical Consultancy Application",
            date: "March 16, 2024",
            comment: "2 Comments",
            name:"Shefali Sharama",
            title: "Mobile App Development",
            images: (require('../assests/images/Software/blog/rv-34-blog11.png')),
        },

        {
            id: 1,
            nameID:"logistics-mobile-application",
            heading: "Logistics Mobile Application",
            date: "March 12, 2024",
            comment: "2 Comments",
            title: "Mobile App Development",
            images: (require('../assests/images/Software/blog/rv-34-blog2.jpg')),
        },
    ],

    // portFolioAlpha: [
    //     {
    //         id: 0,
    //         nameID: "tax-filling-application",
    //         heading: "Tax Filling Application",
    //         images: (require('../assests/images/Software/Portfolio/rv-34-portfolio-img1.jpg')),
    //         title: "Application"

    //     },
    //     {
    //         id: 1,
    //         nameID: "management-application",
    //         heading: "Management Application",
    //         images: (require('../assests/images/Software/Portfolio/rv-34-portfolio-img2.jpg')),
    //         title: "Application"

    //     },
    //     {
    //         id: 2,
    //         nameID: "babyCare-application",
    //         heading: "BabyCare Application",
    //         images: (require('../assests/images/Software/Portfolio/rv-34-portfolio-img3.jpg')),
    //         portfolioImage: (require('../assests/images/Software/Portfolio/rv-34-portfolio-img3.jpg')),
    //         title: "Application"
    //     },
    //     {
    //         id: 3,
    //         nameID: "student-tutor-application",
    //         heading: "Student Tutor Application",
    //         images: (require('../assests/images/Software/Portfolio/rv-34-portfolio-img4.jpg')),
    //         title: "Application"
    //     },
    //     {
    //         id: 4,
    //         nameID: "ancla-application",
    //         heading: "Ancla Application",
    //         images: (require('../assests/images/Software/Portfolio/rv-34-portfolio-img5.jpg')),
    //         title: "Application"

    //     },
    //     {
    //         id: 5,
    //         nameID: "merchant",
    //         heading: "Merchant",
    //         images: (require('../assests/images/Software/Portfolio/rv-34-portfolio-img6.jpg')),
    //         title: "Application"

    //     },
    //     {
    //         id: 6,
    //         nameID: "fashion-network-application",
    //         heading: "Fashion network Application",
    //         images: (require('../assests/images/Software/Portfolio/rv-34-portfolio-img7.jpg')),
    //         title: "Application"

    //     },

    // ],


    about_Speakers: [
        {
            id: 0,
            title: "Lead Speaker",
            name: "Thomas R. Toe",
            video: (require('../assests/images/speaker-1.mp4'))
        },
        {
            id: 1,
            title: "Lead Speaker",
            name: "Thomas R. Toe",
            video: (require('../assests/images/speaker-2.mp4'))
        },
        {
            id: 2,
            title: "Lead Speaker",
            name: "Thomas R. Toe",
            video: (require('../assests/images/speaker-3.mp4'))
        },
        {
            id: 3,
            title: "Lead Speaker",
            name: "Thomas R. Toe",
            video: (require('../assests/images/speaker-4.mp4'))
        },
        {
            id: 4,
            title: "Lead Speaker",
            name: "Thomas R. Toe",
            video: (require('../assests/images/speaker-2.mp4'))
        },
    ],
   
}